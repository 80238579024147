import React from 'react'
import "./term.css"
import Navbar from '../nav/navbar'
import Nextstep from '../nextStep/nextstep'
import Footer from '../footer/footer'

const Term = () => {
  return (
    <div>
        <Navbar />

        <div className='haodapay__certificateBannerTop w-full'>
            <div className='aboutUs__bannerVideo w-full'>
                <video loop autoPlay muted playsinline className=' w-full h-full'>
                    <source src='\images\Web 800_1.mp4' type='video/mp4' />
                </video>
                <div className='backend__bannerTitle'>
                    <span className='block text-center'>Terms and Condition</span>
                </div>
            </div>        
        </div>

        <div className='container mx-auto px-5 lg:px-10 xl:px-20 flex flex-wrap gap-x-14 my-20'>
            <div className='lg:sticky top-0 lg:w-4/12 term__content'>
                <span className='term__section block mb-6'>Sections</span>
                <ul className='term__title pl-5'>
                    <li><a href="#General-Terms" className='hover:underline hover:text-blue-500'>1.General Terms. </a></li>
                    <li><a href="#registration" className='hover:underline hover:text-blue-500'>2.Registration. </a></li>
                    <li><a href="#eligibility" className='hover:underline hover:text-blue-500'>3.Eligibility </a></li>
                    <li><a href="#platform-overview" className='hover:underline hover:text-blue-500'>4.Platform Overview. </a></li>
                    <li><a href="#payment-service-provider" className='hover:underline hover:text-blue-500'>5.Payment service provider. </a></li>
                    <li><a href="#content" className='hover:underline hover:text-blue-500'>6.Content. </a></li>
                    <li><a href="#indemnity" className='hover:underline hover:text-blue-500'>7.Indemnity. </a></li>
                    <li><a href="#limitation-of-liability" className='hover:underline hover:text-blue-500'>8.Limitation Of Liability.</a></li>
                    <li><a href="#term" className='hover:underline hover:text-blue-500'>9.Term. </a></li>
                    <li><a href="#termination" className='hover:underline hover:text-blue-500'>10.Termination. </a></li>
                    <li><a href="#communication" className='hover:underline hover:text-blue-500'>11.Communication. </a></li>
                    <li><a href="#User-Obligations" className='hover:underline hover:text-blue-500'>12.User Obligations And Formal Undertakings As To Conduct. </a></li>
                    <li><a href="#refunds" className='hover:underline hover:text-blue-500'>13.Refunds. </a></li>
                    <li><a href="#suspension-of-user" className='hover:underline hover:text-blue-500'>14.Suspension Of User Access And Activity. </a></li>
                    <li><a href="#intellectual-property" className='hover:underline hover:text-blue-500'>15.Intellectual Property Rights. </a></li>
                    <li><a href="#disclaimer-of-warranties" className='hover:underline hover:text-blue-500'>16.Disclaimer Of Warranties And Liabilities. </a></li>
                    <li><a href="#force-majeure" className='hover:underline hover:text-blue-500'>17.Force Majeure </a></li>
                    <li><a href="#dispute-resolution" className='hover:underline hover:text-blue-500'>18.Dispute Resolution And Jurisdiction. </a></li>
                    <li><a href="#miscellaneous-provisions" className='hover:underline hover:text-blue-500'>19.Miscellaneous Provisions. </a></li>
                </ul>
            </div>
            <div className='lg:w-3/5 mt-20 text-justify lg:mt-0'>
                <span className='termContent__title block'>Welcome to Haoda Terms and Condition.</span>
                <div className='my-6 term__para'>
                    <span className='block'>This legal agreement is an electronic record in terms of the Indian Information Technology Act, 2000 and rules there under as applicable and the amended provisions about electronic records in various statutes as amended by the Indian Information Technology Act, 2000. This electronic record is generated by a computer system and does not require any physical or digital signatures.</span>
                    <span className='block my-5'>This legal document is published in accordance with the provisions of Rule 3 (1) of the Indi-an Information Technology (Intermediaries guidelines) Rules, 2011 and Rule 4 of the In-formation Technology (Reasonable security practices and procedures and sensitive per-sonal data or information) Rules, 2011 of Information Technology Act, 2000 amended through Information Technology Amendment Act, 2008 that require publishing the Terms of Use and practices for access and usage of any functional website.</span>
                    <span className='block'>This Website is created and operated by M/s Haoda Payment Solutions Private Limited, (hereinafter referred to as “We”, “Our”, and “Us”), operating under a brand name ‘MyPayHaoda’ having its registered address at “No.36 Navalar Nagar, Anna Nagar West, Chennai-600040”. We intend to ensure your steady commitment to the usage of this Website and the services provided by us through our website “www.mypayhaoda.com”. (“Website”).</span>
                    <span className='block mt-5'>For the purpose of these Terms of Use (“Terms of Use”), wherever the context so requires, “We”, “Company”, “Our”, and “Us” shall mean and refer to the Website. “You”, “Your”, “Yourself”, “User” shall mean and refer to natural and legal individuals or firms who shall be users of this website provided by us and who is competent to enter into binding contracts, as per Indian laws. “Third Parties” refer to any website, platform or individual apart from the Users and the creator of this Website.</span>
                </div>
                
                <div className='' id='General-Terms'>
                    <span className='ternContent__right mb-6 block'>1. General Terms</span>
                    <ul className='flex flex-col gap-y-5 ternContent__para pl-5'>
                        <li>The headings of each section in these Terms of Use are only for the purpose of organ-izing the various provisions under these Terms of Use in an orderly manner and shall be used by you to interpret the provisions contained herein in a manner as may apply to you. Further, it is specifically agreed by you that the headings shall have legal or contractual value on your usage of the website.</li>
                        <li>The use of this website is solely governed by these Terms of Use, Privacy Policy that may be uploaded on the website and any modifications or amendments made thereto by us from time to time, at our sole discretion. If you as a user continue to access and use this website, you are agreeing to comply with and be bound by the following Terms of Use and Privacy Policy.</li>
                        <li>You expressly agree and acknowledge that these Terms of Use and Privacy Policy are co-terminus in nature and that expiry/termination of either one will lead to the termi-nation of the other.</li>
                        <li>You unequivocally agree that these Terms of Use and the Privacy Policy constitute a legally binding agreement between us, and that you shall be subject to the rules, guide-lines, policies, terms, and conditions applicable to your use of the website, and that the same shall be deemed to be incorporated into these Terms of Use and shall be treated as part and parcel of the same. You acknowledge and agree that no signature or ex-press act is required to make these Terms of Use and the Privacy Policy binding on you and that your act of visiting any part of the website constitutes your full and final acceptance of these Terms of Use and the Privacy Policy.</li>
                        <li>We reserve the sole and exclusive right to amend or modify these Terms of Use with-out any prior permission or intimation to you, and you expressly agree that any such amendments or modifications shall come into effect immediately. If you do not adhere to the changes, You must stop using the website at once. Your continued use of the website will signify your acceptance of the changed terms.</li>
                    </ul>
                </div>

                <div className='my-6' id='registration'>
                    <span className='ternContent__right mb-5 block'>2. Registration</span>
                    <ul className='flex flex-col gap-y-5 ternContent__para pl-5'>
                        <span className='block ternContent__para term__para'>Registration is mandatory for all Users of the platform. The Registrations shall be deemed complete only upon the User's authentication and verification of the User's details through mobile or email verification.</span>
                    </ul>
                </div>

                <div id='eligibility' className=''>
                    <span className='ternContent__right mb-6 block'>3. Eligibility</span>
                    <ul className='flex flex-col gap-y-5 ternContent__para term__para pl-5'>
                        <li>You represent and warrant that you are competent and eligible to enter into legally binding agreements and of age and that you have the requisite authority to bind themselves to these Terms of Use following the Law. However, if you are a minor using this Website, you may do so with the consent of your legal guardian. All Acts of minor shall be deemed to be considered as the acts undertaken under the supervision of their legal guardian.</li>
                        <li>You further represent that you will comply with these Terms of Use and all applicable local, state, national and international laws, rules and regulations.</li>
                        <li>You shall not use the Website if you are not competent to contract or are disqualified from doing so by any other applicable law, rule or regulation currently in force.</li>
                    </ul>
                </div>

                <div id='platform-overview' className=''>
                    <span className='ternContent__right my-6 block'>4. Platform Overview</span>
                    <ul className='flex flex-col gap-y-5 ternContent__para pl-5'>
                        <span className='block ternContent__para term__para'>The Website is operated by ‘M/s. Haoda Payment Solutions Private Limited’ which prominently deals in providing Fin-Tech solutions. The Website provides payment solutions that helps users to collect payment in various mediums such as UPI with QR, Net-Banking, Debit & Credit Card payments using tailor-made services for the customers in both collec-tions and payments. The Website also provide financial advisory and financial management services based on the type of services provided by the business platform. Further, the Web-site provides services related to handling the payment collections under UPI (OR Mode), Net-banking, Debit & Credit Cards by generating payment links, net-banking links, web hooks, payment buttons, payment subscriptions. Also, we provide bulk pay-out services in UPI payout, Net-Banking Payouts, IMPS, NEFT, RTGS. The Website may also adopt a preference based service for the future mode of payment collections methods according to the development. The functionalities of the Website operates following the payment and set-tlement guidelines as applicable in India to provide Payment service provider solutions digitally and the Website will provide required solutions for seamless bulk transactions settlements services.</span>
                    </ul>
                </div>

                <div id='payment-service-provider' className=''>
                    <span className='ternContent__right my-6 block'>5. Payment service provider</span>
                    <ul className='flex flex-col gap-y-5 ternContent__para pl-5'>
                        <span className='block ternContent__para term__para'>All text, graphics, User interfaces, visual interfaces, photographs, trademarks, logos, brand names, descriptions, sounds, music and artwork (collectively, ‘Content’), is generated/provided or based on information provided by the users or third parties and We has no control and make no guarantees regarding the quality, the accuracy, integrity or genuineness of such content or such other information provided on the Website. All the Content displayed on the Website is subject to copyright and shall not be reused by You (or a third party) without the prior written consent from Us and the copyright owner. You are solely responsible for the integrity, authenticity, quality and genuineness of the content provided on the Website and whilst feedback and comments by You can be made via the Website, we bear no liability whatsoever for any feedback or comments made by the other Users or made in respect of any of the content on the Website. Further, the Website reserves its right to suspend the account of any User for an indefinite period to be decided at the discretion of the Website or to terminate the account of any User who is found to have created or shared or submitted any content or part thereof that is found to be untrue/inaccurate/misleading or offensive/vulgar. You shall be solely responsible for making good any financial or legal losses incurred through the creation/sharing/submission of Content or part thereof that is deemed to be untrue/inaccurate/misleading. You have a personal, non-exclusive, non-transferable, revocable, limited privilege to access the content on the Website. You shall not copy, adapt, and modify any content without written permission from Us.</span>
                    </ul>
                </div>

                <div id='content' className=''>
                    <span className='ternContent__right my-6 block'>6. Content</span>
                    <ul className='flex flex-col gap-y-5 ternContent__para pl-5'>
                        <span className='block ternContent__para term__para'>All text, graphics, User interfaces, visual interfaces, photographs, trademarks, logos, brand names, descriptions, sounds, music and artwork (collectively, ‘Content’), is generated/provided or based on information provided by the users or third parties and We has no control and make no guarantees regarding the quality, the accuracy, integrity or genuineness of such content or such other information provided on the Website. All the Content displayed on the Website is subject to copyright and shall not be reused by You (or a third party) without the prior written consent from Us and the copyright owner. You are solely responsible for the integrity, authenticity, quality and genuineness of the content provided on the Website and whilst feedback and comments by You can be made via the Website, we bear no liability whatsoever for any feedback or comments made by the other Users or made in respect of any of the content on the Website. Further, the Website reserves its right to suspend the account of any User for an indefinite period to be decided at the discretion of the Website or to terminate the account of any User who is found to have created or shared or submitted any content or part thereof that is found to be untrue/inaccurate/misleading or offensive/vulgar. You shall be solely responsible for making good any financial or legal losses incurred through the creation/sharing/submission of Content or part thereof that is deemed to be untrue/inaccurate/misleading. You have a personal, non-exclusive, non-transferable, revocable, limited privilege to access the content on the Website. You shall not copy, adapt, and modify any content without written permission from Us.</span>
                    </ul>
                </div>

                <div id='indemnity' className=''>
                    <span className='ternContent__right my-6 block'>7. Indemnity</span>
                    <span className='block ternContent__para term__para'>You agree to indemnify, defend and hold harmless the Us, and our respective directors, of-ficers, employees and agents (collectively, "Parties"), from and against any losses, liabili-ties, claims, damages, demands, costs and expenses (including legal fees and disbursements in connection therewith and interest chargeable thereon) asserted against or incurred by Us that arise out of, result from, or maybe payable by, any breach or non-performance of any representation, warranty, covenant or agreement made or obligation to be performed accord-ing to these Terms of Use. Further, You agrees to hold the Us harmless against any claims made by any third party due to, or arising out of, or in connection with:</span>
                    <ul className='flex flex-col gap-y-5 ternContent__para pl-5'>
                        <li>Your use of the Website.</li>
                        <li>Your violation of these Terms of Use.</li>
                        <li>Your violation of any rights of another.</li>
                        <li>Your alleged improper conduct according to these Terms of use.</li>
                        <li>Your conduct in connection with the Website.</li>
                    </ul>
                    <span className='block ternContent__para term__para'>You agree to fully cooperate in indemnifying Us at your expense. You also agree not to set-tle with any party without consent from Us. In no event shall we be liable to compensate You or any third party for any special, inci-dental, indirect, consequential or punitive damages whatsoever, including those resulting from loss of use, data or profits, whether or not foreseeable, and whether or not You had been advised of the possibility of such damages, or based on any theory of liability, includ-ing breach of contract or warranty, negligence or other tortuous action, or any other claim arising out of or in connection with the Your use of or access to the Website and/or the Ser-vices or materials contained therein.</span>
                </div>

                <div id='limitation-of-liability'>
                    <span className='ternContent__right my-6 block'>8. Limitation Of Liability</span>
                    <ul>
                        <li className='block ternContent__para term__para'>We are not responsible for any consequences arising out of the following events:</li>
                    </ul>
                    <span className='block ternContent__para term__para'>If the Website is inoperative/non-responsive due to any connectivity er-rors associated with the internet connection such as but not limited to slow connectivity, no connectivity, server failure;</span>
                    <span className='block ternContent__para my-3 term__para'>If you have fed incorrect information or data or for any deletion of data;</span>
                    <span className='block ternContent__para term__para'>If there is an undue delay or inability to communicate through email;</span>
                    <span className='block ternContent__para my-3 term__para'>If there is any deficiency or defect in the Services managed by Us;</span>
                    <span className='block ternContent__para term__para'>If there is a failure in the functioning of any other service provided by Us.</span>
                    <ul className='grid gap-y-5 list-disc'>
                        <li className='block ternContent__para term__para'>The Website accepts no liability for any errors or omissions, on behalf of itself, or for any damage caused to You, Your belongings, or any third party, resulting from the use or misuse of the Website or any service availed of by Us through the Website. The service and any Content or material displayed on the service is provided without any guarantees, conditions or warranties as to its accuracy, suitability, completeness or reliability. We will not be liable to you for the unavailability or failure of the Website.</li>
                        <li className='block ternContent__para term__para'>You are to comply with all laws applicable to you or your activities, and with all Policies, which are hereby incorporated into this Terms of Use by reference.</li>
                        <li className='block ternContent__para term__para'>The Website expressly excludes any liability for any loss or damage that was not reasonably foreseeable by the Website and which is incurred by you in connection with the Website, including loss of profits; and any loss or damage incurred by you as a result of your breach of these Terms of Use.</li>
                        <li className='block ternContent__para term__para'>To the fullest extent permitted by law, We shall not be liable to You or any other party for any loss or damage, regardless of the form of action or basis of any claim. You acknowledge and agree that your sole and exclusive remedy for any dispute with us is to terminate your use of the Website.</li>
                    </ul>
                </div>

                <div id='term' className=''>
                    <span className='ternContent__right my-6 block'>9. Term</span>
                    <ul className='flex flex-col gap-y-5 ternContent__para pl-5'>
                        <li>These Terms of Use shall continue to form a valid and binding contract between us and shall continue to be in full force and effect until you continue to access and use the Websites.</li>
                        <li>You may terminate your use of the Website at any time.</li>
                        <li>We may terminate these Terms of Use and close your account at any time without notice and/or suspend or terminate Your access to the Website at any time and for any reason, if any discrepancy or legal issue arises.</li>
                        <li>Such suspension or termination shall not limit our right to take any other action against you that we consider appropriate.</li>
                        <li>It is also hereby declared that we may discontinue the Services and Websites without any prior notice.</li>
                    </ul>
                </div>

                <div id='termination' className=''>
                    <span className='ternContent__right my-6 block'>10. Termination</span>
                    <ul className='flex flex-col gap-y-5 ternContent__para pl-5'>
                        <li>We reserve the right, in its sole discretion, to unilaterally terminate Your access to the Website, or any portion thereof, at any time, without notice or cause.</li>
                        <li>We also reserve the universal right to deny access to You, to any/all of are on its Web-site without any prior notice/explanation to protect the interests of the Website and/or other Users to the Website.</li>
                        <li>We reserve the right to limit, deny or create different access to the Website and its fea-tures concerning different Users, or to change any of the features or introduce new features without prior notice.</li>
                        <li>You shall continue to be bound by these Terms of use, and it is expressly agreed to by You that You shall not have the right to terminate these Terms of Use till the expiry of the same.</li>
                    </ul>
                </div>

                <div id='communication' className=''>
                    <span className='ternContent__right my-6 block'>11. Communication</span>
                    <ul className='flex flex-col gap-y-5 ternContent__para pl-5'>
                        <li>By using this Website and providing Your identity and contact information to Us through the Website, You agree and consent to receive calls, e-mails or SMS from Us and/or any of its representatives at any time.</li>
                        <li>You can report to info@haodapayments.com if you find any discrepancy with regard to Website or content-related information and we will take necessary action after an investigation. The response with resolution (if any issues found) shall be dependent on the time is taken for investigation.</li>
                        <li>You expressly agree that notwithstanding anything contained herein above, it may be con-tacted by Us relating to any services availed by You on the Website or anything pursuant thereto and You agree to indemnify Us from any harassment claims. It is expressly agreed to by Us that any information shared by Us shall be governed by the Privacy Policy.</li>
                    </ul>
                </div>

                <div id='User-Obligations' className=''>
                    <span className='ternContent__right my-6 block'>12. User Obligations And Formal Undertakings As To Conduct</span>
                    <ul className='flex flex-col gap-y-5 ternContent__para pl-5'>
                        <li>You agree and acknowledges that you are a restricted user of this Website and you:</li>
                        <li>Agree to provide genuine credentials during the process whenever required on the Website. You shall not use a fictitious identity to register. We are not liable if you have provided incorrect information.</li>
                        <li>Agree to ensure the Name, Email address, Address, Mobile number and any such other information that may be provided during account registration is valid at all times and shall keep your information accurate and up-to-date.</li>
                        <li>You agree that You are solely responsible for maintaining the confidentiality of your account password. You agree to notify us immediately of any unauthorized use of your account. We reserve the right to close your account at any time for any or no reason.</li>
                        <li>Authorize the Website to use, store or otherwise process certain personal information and all published Content, responses, locations, User comments, reviews and ratings for personalization of Services, marketing and promotional purposes and for optimisation of User-related options and Services.</li>
                        <li>Understand and agree that, to the fullest extent permissible by law, the Website or any of their affiliates or their respective officers, directors, employees, agents, licensors, representatives, operational service providers, advertisers or suppliers shall not be liable for any loss or damage, of any kind, direct or indirect, in connection with or arising from the use of the Website or this terms of use, including, but not limited to, compensatory, consequential, incidental, indirect, special or punitive damages.</li>
                        <li>Are bound not to cut, copy, modify, recreate, reverse engineer, distribute, disseminate, post, publish or create derivative works from, transfer, or sell any information or obtained from the Website. Any such use/limited use of the Website will only be allowed with the prior express written permission.</li>
                        <li>Agree not to access (or attempt to access) the Website and/or the materials or Services by any means other than through the interface provided by the Website. The use of deep-link, robot, spider or other automatic devices, program, algorithm or methodology, or any similar or equivalent manual process, to access, acquire, copy or monitor any portion of the Website or its content, or in any way reproduce or circumvent the navigational structure or presentation of the Website, materials or any content, or to obtain or attempt to obtain any materials, documents or information through any means not specifically made available through the Website will lead to suspension or termination of your access to the Website. We disclaim any liabilities arising concerning such offensive content on the Website.</li>
                        <li>Expressly agree and acknowledge that the Content generated by the Users and displayed on the Website is not owned by Us and that We are in no way responsible for the content of the same. You may, however, report any offensive or objectionable content, which We may then remove from the Website, at our sole discretion. </li>
                        <li>You further undertake not to:</li>
                        <li>Engage in any activity that interferes with or disrupts access to the Website or the services provided therein (or the servers and networks which are connected to the Website);</li>
                        <li>Impersonate any person or entity, or falsely state or otherwise misrepresent his/her affiliation with a person or entity;</li>
                        <li>Probe, scan or test the vulnerability of the Website or any network connected to the Website, nor breach the security or authentication measures on the Website or any network connected to the Website. The User may not reverse look-up, trace or seek to trace any information relating to any other User of, or visitor to, the Website, or any other viewer of the Website, including any User account maintained on the Website not operated/managed by the User, or exploit the Website or information made availa-ble or offered by or through the Website, in any manner;</li>
                        <li>Disrupt or interfere with the security of, or otherwise cause harm to, the Website, systems resources, accounts, passwords, servers, or networks connected to or accessi-ble through the Website or any affiliated or linked Websites.</li>
                        <li>Use the Website or any material or content therein for any purpose that is unlawful or prohibited by these Terms of Use, or to solicit the performance of any illegal activity or other activity which infringes the rights of this Website or any other third party (s);</li>
                        <li>Violate any code of conduct or guideline which may apply for or to any particular service offered on the Website;</li>
                        <li>Violate any applicable laws, rules or regulations currently in force within or outside India;</li>
                        <li>Violate any portion of these Terms of Use or the Privacy Policy, including but not limited to any applicable additional terms of the Website contained herein or else-where, whether made by amendment, modification, or otherwise;</li>
                        <li>Commit any act that causes Us to lose (in whole or in part) the Services of its Internet Establishment ("ISP") or in any manner disrupts the Services of any other suppli-er/service provider of the Website;</li>
                        <li>You hereby expressly authorize Us to disclose any and all information relating to You in our possession to law enforcement or other government officials, as We may in our sole discretion, believe necessary or appropriate in connection with the investigation and/or resolution of possible crimes, especially those involve personal injury and theft/infringement of intellectual property. You further understand that the Website might be directed to disclose any information (including the identity of persons providing information or materials on the Website) as necessary to satisfy any judicial Order, law, regulation or valid governmental request.</li>
                        <li>By indicating Your acceptance to use any services offered on the Website, You are obligated to complete such transactions after making payment. You shall be prohibited from indicating their acceptance to avail services where the transactions have remained incomplete.</li>
                        <li>You agree to use the services provided by Us, our affiliates, consultants and contracted companies, for lawful purposes only.</li>
                        <li>You agree to provide authentic and true information. We reserve the right to confirm and validate the information and other details provided by You at any point in time. If upon confirmation such details are found to be false, not to be true (wholly or partly), We shall in our sole discretion reject the registration and debar You from using the Website without prior intimation whatsoever.</li>
                        <li>Belongs to another person and to which you have no right to;</li>
                        <li>Is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, pedophilic, libellous, invasive of another's privacy, hateful, or racially, ethnically objectionable, disparaging, relating or encouraging money laundering or gambling, or otherwise unlawful in any manner whatever;</li>
                        <li>Is in any way harmful to minors;</li>
                        <li>Violates any law for the time being in force;</li>
                        <li>Deceives or misleads the addressee about the origin of such messages or communicates any information which is grossly offensive or menacing;</li>
                        <li>Abuse, harass, threaten, defame, disillusion, erode, abrogate, demean or otherwise violate the legal rights of others;</li>
                        <li>Impersonate any person or entity, or falsely state or otherwise misrepresent Your affiliation with a person or entity;</li>
                        <li>Publish, post, disseminate, any grossly harmful information, harassing, blasphemous, defamatory, obscene, pornographic, pedophilic, libellous, invasive of another's privacy, hateful, or racially, ethnically objectionable, disparaging, relating or encouraging money laundering or gambling, or otherwise unlawful in any manner whatever; or unlawfully threatening or unlawfully harassing including but not limited to "indecent representation of women" within the meaning of the Indecent Representation of Women (Prohibition) Act, 1986;</li>
                        <li>Threatens the unity, integrity, defence, security or sovereignty of India, friendly relations with foreign states, or public order or causes incitement to the commission of any cognizable offence or prevents investigation of any offence or is insulting any other nation.</li>
                   </ul>
                </div>

                <div id='refunds' className=''>
                    <span className='ternContent__right my-6 block'>13. Refunds</span>
                    <ul>
                         <span className='block ternContent__para term__para'>In the event of disputed payments, all refunds shall be made to the original method in which the disputed transaction occurred. In the event that the user raises a Refund Request to with or fails to contest such Disputed Transaction within the aforesaid _____ working days or contests Disputed Transaction without providing supporting documentation to us, payment service providers, Card Payment Network and/ or issuing institution's satisfaction, we shall be entitled to recover the Refund Monies from credits subsequently made to the escrow account with respect to payments made by the seller's buyers. b. All payments with respect to refunds shall be solely your responsibility and we shall not be liable for any claims, disputes, penalties which may arise in connection with such refunds to you or the Customer. You shall indemnify us in respect of any claims, disputes, penalties, costs, and expenses arising directly or indirectly in relation to refunds and Dis-putes for all Transactions. c. The User hereby agrees and acknowledged by the parties that the Fees charged by us in respect of the Disputed Transaction will not be refunded or repaid by us to the user, buyer or any other person.</span>
                    </ul>
                </div>

                <div id='suspension-of-user' className=''>
                    <span className='ternContent__right my-6 block'>14. Suspension Of User Access And Activity</span>
                    <span className='block ternContent__para term__para'>Not with standing other legal remedies that may be available, we may in our sole discretion, limit your access and/or activity by immediately removing your access credentials either temporarily or indefinitely, or suspend/terminate your association with the Website, and/or refuse to the usage of the Website, without being required to provide you with notice or cause:</span>
                    <ul className='flex flex-col gap-y-5 ternContent__para pl-5'>
                        <li>If you are in breach of any of these Terms of Use or the Privacy Policy;</li>
                        <li>If you have provided wrong, inaccurate, incomplete or incorrect information;</li>
                        <li>If your actions may cause any harm, damage or loss to the other Users or Us, at our sole discretion.</li>
                    </ul>
                    <span className='block ternContent__para term__para'>You agree to fully cooperate in indemnifying Us at your expense. You also agree not to set-tle with any party without consent from Us. In no event shall we be liable to compensate You or any third party for any special, inci-dental, indirect, consequential or punitive damages whatsoever, including those resulting from loss of use, data or profits, whether or not foreseeable, and whether or not You had been advised of the possibility of such damages, or based on any theory of liability, includ-ing breach of contract or warranty, negligence or other tortuous action, or any other claim arising out of or in connection with the Your use of or access to the Website and/or the Ser-vices or materials contained therein.</span>
                </div>

                <div id='intellectual-property' className=''>
                    <span className='ternContent__right my-6 block'>15. Intellectual Property Rights</span>
                    <ul className='flex text-justify flex-col gap-y-5 ternContent__para pl-5'>
                        <li>Unless expressly agreed to in writing, nothing contained herein shall give you a right to use any of the trade names, trademarks, service marks, logos, domain names, information, ques-tions, answers, solutions, reports and other distinctive brand features, save according to the provisions of these Terms of Use that shall be available on the website. All logos, trade-marks, brand names, service marks, domain names, including material, designs, and graphics created by and developed by either the website or such other third party and other distinctive brand features of the website are the property of the website or the respective copyright or trademark owner. Furthermore, concerning the website, we shall be the exclu-sive owner of all the designs, graphics and the like, related to the Website.</li>
                        <li>You shall not use any of the intellectual property displayed on the Website in any manner that is likely to cause confusion among existing or prospective users of the website, or that in any manner disparages or discredits the website, to be determined in the sole discretion.</li>
                        <li>You are aware all intellectual property, including but not limited to copyrights, relating to said services resides with the owners, and that at no point does any such intellectual property stand transferred from the aforementioned creators. You are aware that we merely provide the Website through which You can communicate with other users and the Website does not own any of the intellectual property relating to the independent content displayed on the Website, apart from created graphics and specified content.</li>
                        <li>You are further aware that any reproduction or infringement of the intellectual property of the aforementioned owners by You will result in legal action being initiated against You by the respective owners of the intellectual property so reproduced/infringed upon. It is agreed to by You that the contents of this section shall survive even after the termination or expiry of these Terms of Use and/or Privacy Policy.</li>
                    </ul>
                </div>

                <div id='disclaimer-of-warranties' className=''>
                    <span className='ternContent__right my-6 block'>16. Disclaimer Of Warranties And Liabilities</span>
                    <ul className='flex flex-col gap-y-5 ternContent__para pl-5'>
                        <li>You further agree and undertake that you are accessing the Website at your sole risk and are that you are using the best and prudent judgment before availing of any features on the Website or accessing/using any information displayed thereon.</li>
                        <li>You agree that any kind of information, resources, activities, recommendations obtained/availed from the Website, written or oral, will not create any warranty and we disclaim all liabilities resulting from these.</li>
                        <li>We do not guarantee that the features and content contained in the Website will be uninterrupted or error-free, or that the Website or its server will be free of viruses or other harmful components, and You hereby expressly accepts any associated risks involved with your use of the Website.</li>
                        <li>It is further agreed to by You that the contents of this section shall survive even after the termination or expiry of the Terms of Use and/or Privacy Policy.</li>
                    </ul>
                </div>

                <div id='force-majeure' className=''>
                    <span className='ternContent__right my-6 block'>17. Force Majeure</span>
                    <span className='block ternContent__para term__para'>We will not be liable for damages for any delay or failure to perform our obligations here-under if such delay or failure is due to cause beyond our control or without its fault or negli-gence, due to Force Majeure events including but not limited to acts of war, acts of God, earthquake, riot, fire, festive activities sabotage, labour shortage or dispute, internet interrup-tion, technical failure, breakage of sea cable, hacking, piracy, cheating, illegal or unauthorized.</span>
                </div>

                <div id='dispute-resolution' className=''>
                    <span className='ternContent__right my-6 block'>18. Dispute Resolution And Jurisdiction</span>
                    <ul className='flex flex-col gap-y-5 ternContent__para pl-5'>
                        <li>It is expressly agreed to by you hereto that the formation, interpretation, and performance of these Terms of Use and any disputes arising therefrom will be resolved through Arbitration. It is further agreed to by you that the contents of this section shall survive even after the termination or expiry of the Terms of Use and/or Privacy Policy.</li>
                        <li>Arbitration: In the event that the Parties are unable to amicably resolve a dispute, the said dispute will be referred to arbitration by a sole arbitrator to be appointed by us, and the award passed by such sole arbitrator will be valid and binding on all parties. All cost of the Arbitration shall be borne solely in accordance with the Arbitrators award. The arbitration shall be conducted in English, and the seat of Arbitration shall be the city of Chennai, India.</li>
                        <li>You expressly agree that the Terms of Use, Privacy Policy and any other agreements entered into between the Parties are governed by the laws, rules, and regulations of India.</li>
                    </ul>
                </div>

                <div id='miscellaneous-provisions' className=''>
                    <span className='ternContent__right my-6 block'>19. Miscellaneous Provisions</span>
                    <ul className='flex flex-col gap-y-5 ternContent__para pl-5'>
                        <li>Entire Agreement: These Terms of Use, read with the Privacy Policy, form the complete and final contract between us with respect to the subject matter hereof and supersedes all other communications, representations, and agreements (whether oral, written or otherwise) relating thereto.</li>
                        <li>Waiver: The failure at any time to require performance of any provision of these Terms of Use shall in no manner affect our right at a later time to enforce the same. No waiver by us of any breach of these Terms of Use, whether by conduct or other-wise, in any one or more instances, shall be deemed to be or construed as a further or continuing waiver of any such breach, or a waiver of any other breach of these Terms of Use.</li>
                        <li>Severability: If any provision/clause of these Terms of Use is held to be invalid, ille-gal or unenforceable by any court or authority of competent jurisdiction, the validity, legality, and enforceability of the remaining provisions/clauses of these Terms of Use shall in no way be affected or impaired thereby, and each such provision/clause of these Terms of Use shall be valid and enforceable to the fullest extent permitted by law. In such case, these Terms of Use shall be reformed to the minimum extent nec-essary to correct any invalidity, illegality or unenforceability, while preserving to the maximum extent the original rights, intentions and commercial expectations of the Parties hereto, as expressed herein.</li>
                        <li>Contact Us: If you have any questions about these Terms of Use, the practices of the website, or your experience, you can contact us by emailing at info@haodapayments.com</li>
                    </ul>
                </div>
            </div>
        </div>

        <Nextstep />

        <Footer />

    </div>
  )
}

export default Term