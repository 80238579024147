import React from 'react'
import './eCommerce.css'
import Navbar from '../nav/navbar'
import Nextstep from '../nextStep/nextstep'
import Footer from '../footer/footer'
import { FaArrowRight } from 'react-icons/fa'
import { easeInOut, motion } from 'framer-motion'

const Ecommerce = () => {
  return (
    <>
       <Navbar />
      <div className='ECommerce__banner mt-16'>
            <div className='ECommerce__bannerVideo'>
                <video loop autoPlay muted playsinline >
                    <source src='\images\Web 800_1.mp4' type='video/mp4' />
                </video>
                <div className='ECommerce__bannerTitle'>
                    <div className='container mx-auto retail__banner__background lg:flex justify-center items-center'>
                        <div className='lg:w-1/2'>
                            <div className='ECommerce__heading text-center lg:text-left'>Ready to Enhance Your <span>E-commerce</span> Business?</div>
                            <div className='flex justify-center text-center lg:text-left my-8'><span className='ECommerce__bodyText'>Partner with Haoda Pay for a dependable, secure, and user-friendly payment solution specifically designed for the Indian e-commerce market.</span></div>
                            <a href='https://merchant-beta.mypayhaoda.com/auth/sign-in' target='_black'> <div class="contact__btn flex justify-center lg:justify-start items-start">
                               <button className='flex items-center justify-center gap-1 lg:justify-start px-5 py-3'>Sign Up
                                   <span className='arrow__contact flex justify-center ml-1 text-xs items-center mt-1'><FaArrowRight className='move__arrow block' /></span>
                               </button>
                            </div></a>
                         </div>
                         <div className=' flex lg:w-1/2 items-center justify-center lg:justify-end'>
                              <div className='EducationBoy flex rounded-xl items-center justify-center lg:justify-end'>
                                   <img className=' rounded-xl' src="\images\E-commerce.jpg" alt='img' />
                              </div>
                         </div>
                    </div>
                </div>
            </div>    
       </div>

       {/* second__page */}

       <motion.div className='container my-auto mx-auto py-10  lg:py-20'
       initial={{ opacity: 0,
        y: "70px"
       }}
      whileInView={{ opacity: 1, y:"0px" }}
      transition={{ 
        duration: 1.2,
        ease: easeInOut,
        delay: 0.4 
       }}
      viewport={{once:true}}>
           <div className='StandsOut_E-commerce__page'>
            <div className='StandsOut_E-commerce__heading mb-10'>
               Why <span className='haoda'>Haoda</span> <span className='pay'>Pay</span> Stands Out for E-commerce Payments
           </div>

           <div className='flex flex-col sm:flex-row justify-self-center gap-5 lg:gap-10 lg:items-center items-start'>
               <div className='flex flex-col gap-8 lg:gap-10 justify-center items-start'>
                  <div className='StandsOut_E-commerce__box mb-5'>
                      <div className='flex justify-center sm:justify-start'>
                          <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56" fill="none">
                          <g clip-path="url(#clip0_3779_10356)">
                            <path d="M1.73337 8.64008C1.59381 11.3828 2.70484 14.3046 4.90908 16.5104L7.22903 14.1904C5.96356 12.925 5.18787 11.359 4.98881 9.83697C7.64881 9.80077 9.80131 7.64816 9.83719 4.98816C11.361 5.18678 12.924 5.96367 14.1905 7.22903L16.5105 4.90908C14.3047 2.70473 11.3837 1.59381 8.64019 1.73337C7.73664 0.681297 6.41408 0 4.92188 0C2.20784 0 0 2.20784 0 4.92188C0 6.41408 0.681297 7.73664 1.73337 8.64008Z" fill="#F4B936"/>
                            <path d="M4.92188 56.0005C6.39166 56.0005 7.69759 55.34 8.60027 54.3155C8.75623 54.3239 8.90827 54.3583 9.06511 54.3583C11.6798 54.3583 14.4212 53.1792 16.5104 51.0915L14.1904 48.7716C12.9239 50.0369 11.361 50.813 9.83697 51.0118C9.80077 48.352 7.64859 46.1998 4.98881 46.1636C5.18787 44.6416 5.96356 43.0757 7.22903 41.8102L4.90908 39.4902C2.70484 41.696 1.59381 44.6177 1.73337 47.3605C0.681297 48.2639 0 49.5864 0 51.0786C0 53.7927 2.20784 56.0005 4.92188 56.0005Z" fill="#F4B936"/>
                            <path d="M51.0807 0C49.5885 0 48.2657 0.681297 47.3625 1.73337C44.6167 1.59359 41.6967 2.70452 39.4922 4.90908L41.8121 7.22903C43.0776 5.96356 44.6408 5.18645 46.1655 4.98794C46.2014 7.64794 48.3539 9.80066 51.0139 9.83686C50.8148 11.3589 50.0391 12.9248 48.7736 14.1903L51.0936 16.5103C53.2978 14.3045 54.4089 11.3828 54.2693 8.63997C55.3213 7.73664 56.0026 6.41408 56.0026 4.92187C56.0026 2.20784 53.7947 0 51.0807 0Z" fill="#F4B936"/>
                            <path d="M54.2692 47.3605C54.4088 44.6178 53.2977 41.696 51.0935 39.4902L48.7735 41.8102C50.039 43.0757 50.8147 44.6416 51.0138 46.1636C48.354 46.1998 46.2018 48.3522 46.1656 51.012C44.6407 50.8134 43.0776 50.037 41.8121 48.7716L39.4922 51.0915C41.5815 53.1792 44.3227 54.3583 46.9375 54.3583C47.0943 54.3583 47.2462 54.3239 47.4023 54.3155C48.305 55.3401 49.6109 56.0006 51.0807 56.0006C53.7947 56.0006 56.0026 53.7928 56.0026 51.0787C56.0026 49.5865 55.3213 48.264 54.2692 47.3605Z" fill="#F4B936"/>
                            <path d="M6.64844 26.3594H19.8269C20.1023 16.3228 22.8959 6.5625 28.0028 6.5625C16.7966 6.5625 7.49161 15.3654 6.64844 26.3594Z" fill="#392675"/>
                            <path d="M28.0017 9.84375C26.4138 9.84375 23.4324 15.7664 23.1367 26.3594H32.8667C32.5711 15.7664 29.5896 9.84375 28.0017 9.84375Z" fill="#392675"/>
                            <path d="M28 6.5625C33.1069 6.5625 35.9005 16.3228 36.1759 26.3594H49.3544C48.5112 15.3654 39.2062 6.5625 28 6.5625Z" fill="#392675"/>
                            <path d="M49.3544 29.6406H36.1759C35.9005 39.6772 33.1069 49.4375 28 49.4375C39.2062 49.4375 48.5112 40.6346 49.3544 29.6406Z" fill="#392675"/>
                            <path d="M6.64844 29.6406C7.49161 40.6346 16.7966 49.4375 28.0028 49.4375C22.8959 49.4375 20.1023 39.6772 19.8269 29.6406H6.64844Z" fill="#392675"/>
                            <path d="M28.0017 46.1562C29.5896 46.1562 32.5711 40.2336 32.8667 29.6406H23.1367C23.4324 40.2336 26.4138 46.1562 28.0017 46.1562Z" fill="#392675"/>
                          </g>
                          <defs>
                            <clipPath id="clip0_3779_10356">
                              <rect width="56" height="56" fill="white"/>
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                      <div className='StandsOut_E-commerce__subHeading my-2 2xl:my-6 text-center sm:text-start'>Complete Payment Solutions</div>
                      <div className='StandsOut_E-commerce__text text-center sm:text-start'>Haoda Pay offers comprehensive e-commerce payment solutions tailored to meet India's unique needs. It offers supporting various payment methods, improving conversion rates and customer satisfaction. Haoda Pay's flexibility ensures customers can pay in their preferred way.</div>
                  </div>

                  <div className='StandsOut_E-commerce__box mb-10'>
                       <div className='flex justify-center sm:justify-start'>
                           <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56" fill="none">
                             <g clip-path="url(#clip0_3779_10371)">
                               <path d="M31.2812 14.875H29.6406V18.1562H31.2812C32.1864 18.1562 32.9219 17.4208 32.9219 16.5156C32.9219 15.6104 32.1864 14.875 31.2812 14.875Z" fill="black"/>
                               <path d="M15.1914 21.4374H17.8389L16.5152 17.9062L15.1914 21.4374Z" fill="black"/>
                               <path d="M49.2624 13.7921C48.7375 7.95156 43.8156 3.28125 37.8438 3.28125C35.8751 3.28125 33.9719 3.80614 32.2327 4.75781C29.4438 1.70614 25.6048 0 21.4375 0C13.6609 0 7.2625 6.14677 6.73761 13.7921C2.8 15.1345 0 18.8654 0 23.0781C0 28.436 4.42564 32.9219 9.95312 32.9219H46.0469C51.5122 32.9219 56 28.4557 56 23.0781C56 18.7469 53.1343 15.1047 49.2624 13.7921ZM22.0142 27.8959C21.1539 28.2139 20.2158 27.7761 19.901 26.9362L19.0696 24.7188H13.9615L13.1301 26.9361C12.8113 27.7853 11.8612 28.2066 11.0169 27.8958C10.1694 27.5769 9.73995 26.6316 10.0573 25.7825L14.9791 12.6575C15.4598 11.379 17.5714 11.379 18.0521 12.6575L22.974 25.7825C23.2912 26.6317 22.8618 27.577 22.0142 27.8959ZM31.2812 21.4375H29.6406V26.3594C29.6406 27.2662 28.9068 28 28 28C27.0932 28 26.3594 27.2662 26.3594 26.3594V13.2344C26.3594 12.3275 27.0932 11.5938 28 11.5938H31.2812C33.9953 11.5938 36.2031 13.8016 36.2031 16.5156C36.2031 19.2297 33.9953 21.4375 31.2812 21.4375ZM42.7656 26.3594C42.7656 27.2662 42.0318 28 41.125 28C40.2182 28 39.4844 27.2662 39.4844 26.3594V13.2344C39.4844 12.3275 40.2182 11.5938 41.125 11.5938C42.0318 11.5938 42.7656 12.3275 42.7656 13.2344V26.3594Z" fill="#392675"/>
                               <path d="M8.7083 43.8057L10.3489 46.6473C10.802 47.432 11.8053 47.701 12.59 47.2479L14.2817 46.2712C16.9159 49.3542 20.5662 51.5331 24.7181 52.297V54.3594C24.7181 55.2654 25.4526 56 26.3587 56H29.6399C30.546 56 31.2806 55.2654 31.2806 54.3594V52.297C35.4324 51.5331 39.0827 49.3542 41.7169 46.2712L43.4086 47.2479C44.1933 47.701 45.1967 47.432 45.6497 46.6473L47.2903 43.8057C47.7434 43.021 47.4746 42.0176 46.6899 41.5646L44.9943 40.5857C45.486 39.199 45.7564 37.7196 45.9006 36.2031H39.3183C38.5161 41.7617 33.78 46.0469 27.9993 46.0469C22.2186 46.0469 17.4827 41.7617 16.6803 36.2031H10.0982C10.2424 37.7196 10.5126 39.199 11.0043 40.5857L9.30877 41.5646C8.52412 42.0176 8.25538 43.021 8.7083 43.8057Z" fill="#F4B936"/>
                             </g>
                             <defs>
                               <clipPath id="clip0_3779_10371">
                                 <rect width="56" height="56" fill="white"/>
                               </clipPath>  
                             </defs>
                           </svg>
                       </div>
                       <div className='StandsOut_E-commerce__subHeading my-2 2xl:my-6 text-center sm:text-start'>Smooth Integration with E-commerce Platforms</div>
                       <div className='StandsOut_E-commerce__text text-center sm:text-start'>Our payment solutions seamlessly connect with leading e-commerce platforms, enabling a quick and hassle-free setup. With user-friendly APIs and plugins, you can begin accepting payments in minutes—no technical expertise required.</div>
                  </div>
               </div>

               <div className='lg:flex items-center hidden'>
                      <div className='flex items-center justify-center EcommerceImage1__img'><img className='EcommerceImage1' src='\images\Ecommerce Image 1.png' alt='image' /></div>
               </div>

               <div className='flex flex-col gap-8 lg:gap-10 items-start'>
                    <div className='StandsOut_E-commerce__box mb-10'>
                        <div className='flex justify-center sm:justify-start'>
                           <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56" fill="none">
                              <g clip-path="url(#clip0_3779_10393)">
                                <path d="M1.73337 8.64008C1.59381 11.3828 2.70484 14.3046 4.90908 16.5104L7.22903 14.1904C5.96356 12.925 5.18787 11.359 4.98881 9.83697C7.64881 9.80077 9.80131 7.64816 9.83719 4.98816C11.361 5.18678 12.924 5.96367 14.1905 7.22903L16.5105 4.90908C14.3047 2.70473 11.3837 1.59381 8.64019 1.73337C7.73664 0.681297 6.41408 0 4.92188 0C2.20784 0 0 2.20784 0 4.92188C0 6.41408 0.681297 7.73664 1.73337 8.64008Z" fill="#F4B936"/>
                                <path d="M4.92188 56.0005C6.39166 56.0005 7.69759 55.34 8.60027 54.3155C8.75623 54.3239 8.90827 54.3583 9.06511 54.3583C11.6798 54.3583 14.4212 53.1792 16.5104 51.0915L14.1904 48.7716C12.9239 50.0369 11.361 50.813 9.83697 51.0118C9.80077 48.352 7.64859 46.1998 4.98881 46.1636C5.18787 44.6416 5.96356 43.0757 7.22903 41.8102L4.90908 39.4902C2.70484 41.696 1.59381 44.6177 1.73337 47.3605C0.681297 48.2639 0 49.5864 0 51.0786C0 53.7927 2.20784 56.0005 4.92188 56.0005Z" fill="#F4B936"/>
                                <path d="M51.0807 0C49.5885 0 48.2657 0.681297 47.3625 1.73337C44.6167 1.59359 41.6967 2.70452 39.4922 4.90908L41.8121 7.22903C43.0776 5.96356 44.6408 5.18645 46.1655 4.98794C46.2014 7.64794 48.3539 9.80066 51.0139 9.83686C50.8148 11.3589 50.0391 12.9248 48.7736 14.1903L51.0936 16.5103C53.2978 14.3045 54.4089 11.3828 54.2693 8.63997C55.3213 7.73664 56.0026 6.41408 56.0026 4.92187C56.0026 2.20784 53.7947 0 51.0807 0Z" fill="#F4B936"/>
                                <path d="M54.2692 47.3605C54.4088 44.6178 53.2977 41.696 51.0935 39.4902L48.7735 41.8102C50.039 43.0757 50.8147 44.6416 51.0138 46.1636C48.354 46.1998 46.2018 48.3522 46.1656 51.012C44.6407 50.8134 43.0776 50.037 41.8121 48.7716L39.4922 51.0915C41.5815 53.1792 44.3227 54.3583 46.9375 54.3583C47.0943 54.3583 47.2462 54.3239 47.4023 54.3155C48.305 55.3401 49.6109 56.0006 51.0807 56.0006C53.7947 56.0006 56.0026 53.7928 56.0026 51.0787C56.0026 49.5865 55.3213 48.264 54.2692 47.3605Z" fill="#F4B936"/>
                                <path d="M6.64844 26.3594H19.8269C20.1023 16.3228 22.8959 6.5625 28.0028 6.5625C16.7966 6.5625 7.49161 15.3654 6.64844 26.3594Z" fill="#392675"/>
                                <path d="M28.0017 9.84375C26.4138 9.84375 23.4324 15.7664 23.1367 26.3594H32.8667C32.5711 15.7664 29.5896 9.84375 28.0017 9.84375Z" fill="#392675"/>
                                <path d="M28 6.5625C33.1069 6.5625 35.9005 16.3228 36.1759 26.3594H49.3544C48.5112 15.3654 39.2062 6.5625 28 6.5625Z" fill="#392675"/>
                                <path d="M49.3544 29.6406H36.1759C35.9005 39.6772 33.1069 49.4375 28 49.4375C39.2062 49.4375 48.5112 40.6346 49.3544 29.6406Z" fill="#392675"/>
                                <path d="M6.64844 29.6406C7.49161 40.6346 16.7966 49.4375 28.0028 49.4375C22.8959 49.4375 20.1023 39.6772 19.8269 29.6406H6.64844Z" fill="#392675"/>
                                <path d="M28.0017 46.1562C29.5896 46.1562 32.5711 40.2336 32.8667 29.6406H23.1367C23.4324 40.2336 26.4138 46.1562 28.0017 46.1562Z" fill="#392675"/>
                              </g>
                              <defs>
                                <clipPath id="clip0_3779_10393">
                                  <rect width="56" height="56" fill="white"/>
                                </clipPath>
                              </defs>
                            </svg>
                        </div>
                        <div className='StandsOut_E-commerce__subHeading my-2 2xl:my-6 text-center sm:text-start'>Robust Security Features</div>
                        <div className='StandsOut_E-commerce__text text-center sm:text-start'>We prioritise the security of your transactions and client information. Haoda Pay uses modern security methods, such as end-to-end encryption and real-time fraud detection, to ensure that all payments are processed safely and securely.</div>
                    </div>

                    <div className='StandsOut_E-commerce__box mb-10'>
                        <div className='flex justify-center sm:justify-start'>
                           <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56" fill="none">
                             <g clip-path="url(#clip0_3779_10408)">
                               <path d="M31.2812 14.875H29.6406V18.1562H31.2812C32.1864 18.1562 32.9219 17.4208 32.9219 16.5156C32.9219 15.6104 32.1864 14.875 31.2812 14.875Z" fill="black"/>
                               <path d="M15.1914 21.4374H17.8389L16.5152 17.9062L15.1914 21.4374Z" fill="black"/>
                               <path d="M49.2624 13.7921C48.7375 7.95156 43.8156 3.28125 37.8438 3.28125C35.8751 3.28125 33.9719 3.80614 32.2327 4.75781C29.4438 1.70614 25.6048 0 21.4375 0C13.6609 0 7.2625 6.14677 6.73761 13.7921C2.8 15.1345 0 18.8654 0 23.0781C0 28.436 4.42564 32.9219 9.95312 32.9219H46.0469C51.5122 32.9219 56 28.4557 56 23.0781C56 18.7469 53.1343 15.1047 49.2624 13.7921ZM22.0142 27.8959C21.1539 28.2139 20.2158 27.7761 19.901 26.9362L19.0696 24.7188H13.9615L13.1301 26.9361C12.8113 27.7853 11.8612 28.2066 11.0169 27.8958C10.1694 27.5769 9.73995 26.6316 10.0573 25.7825L14.9791 12.6575C15.4598 11.379 17.5714 11.379 18.0521 12.6575L22.974 25.7825C23.2912 26.6317 22.8618 27.577 22.0142 27.8959ZM31.2812 21.4375H29.6406V26.3594C29.6406 27.2662 28.9068 28 28 28C27.0932 28 26.3594 27.2662 26.3594 26.3594V13.2344C26.3594 12.3275 27.0932 11.5938 28 11.5938H31.2812C33.9953 11.5938 36.2031 13.8016 36.2031 16.5156C36.2031 19.2297 33.9953 21.4375 31.2812 21.4375ZM42.7656 26.3594C42.7656 27.2662 42.0318 28 41.125 28C40.2182 28 39.4844 27.2662 39.4844 26.3594V13.2344C39.4844 12.3275 40.2182 11.5938 41.125 11.5938C42.0318 11.5938 42.7656 12.3275 42.7656 13.2344V26.3594Z" fill="#392675"/>
                               <path d="M8.7083 43.8057L10.3489 46.6473C10.802 47.432 11.8053 47.701 12.59 47.2479L14.2817 46.2712C16.9159 49.3542 20.5662 51.5331 24.7181 52.297V54.3594C24.7181 55.2654 25.4526 56 26.3587 56H29.6399C30.546 56 31.2806 55.2654 31.2806 54.3594V52.297C35.4324 51.5331 39.0827 49.3542 41.7169 46.2712L43.4086 47.2479C44.1933 47.701 45.1967 47.432 45.6497 46.6473L47.2903 43.8057C47.7434 43.021 47.4746 42.0176 46.6899 41.5646L44.9943 40.5857C45.486 39.199 45.7564 37.7196 45.9006 36.2031H39.3183C38.5161 41.7617 33.78 46.0469 27.9993 46.0469C22.2186 46.0469 17.4827 41.7617 16.6803 36.2031H10.0982C10.2424 37.7196 10.5126 39.199 11.0043 40.5857L9.30877 41.5646C8.52412 42.0176 8.25538 43.021 8.7083 43.8057Z" fill="#F4B936"/>
                             </g>
                             <defs>
                               <clipPath id="clip0_3779_10408">
                                 <rect width="56" height="56" fill="white"/>
                               </clipPath>
                             </defs>
                           </svg>
                        </div>
                        <div className='StandsOut_E-commerce__subHeading my-2 2xl:my-6 text-center sm:text-start'>Real-Time Reporting & Analytics</div>
                        <div className='StandsOut_E-commerce__text text-center sm:text-start'>Using our comprehensive reporting capabilities, you can gain vital insights into your business's success. Haoda Pay offers real-time statistics to let you measure revenue, monitor consumer behaviour, and make educated decisions that drive growth.</div>
                    </div>
               </div>
           </div></div>
       </motion.div>

       {/* third__page */}
      <motion.div className='whyChoose__haodapay__bgColor'
      initial={{ opacity: 0,
        y: "70px"
       }}
      whileInView={{ opacity: 1, y:"0px" }}
      transition={{ 
        duration: 1.2,
        ease: easeInOut,
        delay: 0.4 
       }}
      viewport={{once:true}}>
       <div className='mx-auto container py-10 md:py-20'>
             <div className='flex justify-center'> <div className='BenefitsChoose__haodapay__heading text-center mb-16'>Benefits of Choosing Haoda Pay for E-commerce Platform</div></div>
         <div className='BenefitsChoose__haodapay_page flex justify-center'>
            
         <div className='lg:flex items-center lg:gap-10 mt-10'>
             <div className='BenefitsChoose__haodapay__firsthalf flex justify-center'>
                    <div className='flex justify-center EcommerceImage2__img'><img src='\images\payment__housing.png' alt='image' /></div>
             </div>
             <div className='BenefitsChoose__haodapay__secondhalf  whyChoose__haodapay__text'>
                 <div className=''><div className='grid gap-8 sm:text-left text-center'>
                 <div>
                    <div className='BenefitsChoose__haodapay__subHeading'>1.Streamlined Checkout:</div>
                    <div className='sm:mt-4 mt-2'>Minimize cart abandonment with swift and convenient payment options.</div>
                 </div>
                 <div>
                    <div className='BenefitsChoose__haodapay__subHeading'>2.Scalable Solutions:</div>
                    <div className='sm:mt-4 mt-2'>Effortlessly manage high transaction volumes, whether you’re a small business or a large enterprise.</div>
                 </div>
                 <div>
                    <div className='BenefitsChoose__haodapay__subHeading'>3.Enhanced Security:</div>
                    <div className='sm:mt-4 mt-2'>Safeguard your business and customer information with cutting-edge security measures.</div>
                 </div>
                 <div>
                    <div className='BenefitsChoose__haodapay__subHeading'>4.Insight-Driven Growth:</div>
                    <div className='sm:mt-4 mt-2'>Leverage detailed reporting to gain insights into customer behaviour and make informed business decisions.</div>
                 </div>
                 </div></div>
             </div>
         </div> 
         </div>
       </div>
     </motion.div>

       {/* fourth__page */}
       
       <div className='reachUs__bgColor'>
           <div className='mx-auto container lg:py-0 py-10 lg:flex justify-center'>
                <div className='grid gap-y-5 lg:py-24 px-5 lg:pl-5'>
                    <div className='reachUs__heading text-center lg:text-left'>Join <span className='haoda'>Haoda</span> <span className='pay'>Pay</span> Today!</div>
                    <div className='reachUs__text text-center lg:text-left'>Choose Haoda Pay as your e-commerce payment partner and unlock the potential for growth, security, and customer satisfaction. Contact us today to learn more about how our payment solutions can benefit your online store!</div>
                </div>
                <div className='flex justify-center lg:relative lg:justify-start lg:mb-0 lg:items-start reachUs__page2 lg:pt-20 pt-10'>
                <a href='/contact'><div class="contact__btn flex justify-center lg:justify-end items-start">
                       <button className='flex items-center lg:ml-28 gap-x-1 justify-center lg:justify-end px-5 py-3'>Reach Us
                          <span className='arrow__contact flex justify-center ml-1 text-xs items-center mt-1'><FaArrowRight className='move__arrow block' /></span>
                       </button>
                </div></a>
                <div className='reachUs__img absolute left-0 lg:flex hidden justify-items-start justify-self-start justify-start'>
                  <div className='joinHaodapay__revoluiton left-0'><img className='joinHaodapay__revoluiton' src='/images/Housing society image 1.png' alt='image' /></div>
                </div>
              </div>
           </div>
       </div>

       <Nextstep />
       <Footer />
       
    </>
  )
}

export default Ecommerce