import React from 'react'
import './upiPayments.css'
import Navbar from '../nav/navbar'
import Nextstep from '../nextStep/nextstep'
import Footer from '../footer/footer'
import { FaArrowRight } from 'react-icons/fa'
import { easeInOut, motion } from 'framer-motion'

const UPIpayments = () => {
  return (
    <>
     <Navbar />

      <div className='UPIpayments__banner mt-16'>
            <div className='UPIpayments__bannerVideo'>
                <video loop autoPlay muted playsinline >
                    <source src='\images\Web 800_1.mp4' type='video/mp4' />
                </video>
                <div className='UPIpayments__bannerTitle'>
                    <div className='container mx-auto UPIpayments__banner__background flex justify-center items-center'>
                        <div className='lg:w-1/2 flex flex-col justify-center'>
                            <div className='UPIpayments__heading text-center lg:text-left'>Revolutionizing UPI Payments in India</div>
                            <div className=' flex justify-center my-8'><span className='text-center UPIpayments__bodyText lg:text-left'>HaodaPay, we bring you a seamless and secure way to handle your finances through our innovative UPI payment solutions in India. Our platform is designed to provide you with a worry-free online payment experience, backed by cutting-edge security measures that protect your financial data. With HaodaPay, you can enjoy peace of mind knowing that your transactions are safe and secure.</span></div>
                            <a href='/contact'><div class="contact__btn flex justify-center lg:justify-start items-start">
                               <button className='flex items-center justify-center lg:justify-start gap-1 px-5 py-3'>Contact Sales
                                   <span className='arrow__contact flex justify-center ml-1 text-xs items-center mt-1'><FaArrowRight className='move__arrow block' /></span>
                               </button>
                            </div></a>
                         </div>
                         <div className='flex lg:w-1/2 items-center justify-center lg:justify-end'>
                              <div className='EducationBoy flex rounded-xl items-center justify-center lg:justify-end'>
                                   <img className=' rounded-xl' src="\images\UPIPaymentImage__bannerImg.jpg" alt='' />
                              </div>
                         </div>
                    </div>
                </div>
            </div>    
       </div>

       {/* second page */}

       <div className='whyChoose__containerLook flex items-center justify-center md:py-20 py-10'>
         <motion.div className='container mx-auto startedHaodapay__page lg:flex'
         initial={{ opacity: 0,
            y: "70px"
           }}
          whileInView={{ opacity: 1, y:"0px" }}
          transition={{ 
            duration: 1.2,
            ease: easeInOut,
            delay: 0.4 
           }}
          viewport={{once:true}}>
                <div className='lg:w-2/3 w-full grid gap-5 sm:gap-10 sm:text-left text-center'>
                   <span className='startedHaodapay__heading mb-5 lg:mb-10 lg:text-left text-center'>How to Get Started with <span className='haoda'>Haoda</span><span className='pay'> Pay</span></span>
                   <div>
                      <span className='block startedHaodapay__subHeading'>1.Create Your HaodaPay Account:</span>
                      <span className='block startedHaodapay__text mt-1.5 md:mt-3'>Sign up using your mobile number and link your bank account.</span>
                   </div>
                   <div>
                      <span className='block startedHaodapay__subHeading'>2.Find Your HaodaPay ID</span>
                      <span className='block startedHaodapay__text mt-1.5 md:mt-3'>Easily locate your unique HaodaPay ID within the app for seamless transactions.</span>
                   </div>
                   <div>
                      <span className='block startedHaodapay__subHeading'>3.Start Transacting</span>
                      <span className='block startedHaodapay__text mt-1.5 md:mt-3'>Use your HaodaPay ID to send and receive money instantly.</span>
                   </div>
                </div>
                <div className='lg:w-1/3 w-full flex items-center justify-center'>
                    <div className=' flex items-center mt-10 lg:mt-0'><img className='startedHaodapay_img' src='\images\UPI Payment.jpg' alt='image' /></div>
                </div>
          </motion.div>
       </div>

       {/* third__page */}

      <div className='whyChoose__container'> 
      <motion.div className='mx-auto container py-10 md:py-20'
               initial={{ opacity: 0,
                  y: "70px"
                 }}
                whileInView={{ opacity: 1, y:"0px" }}
                transition={{ 
                  duration: 1.2,
                  ease: easeInOut,
                  delay: 0.4 
                 }}
                viewport={{once:true}}>
         <div className='whyChoose__haodapay_page flex justify-center'>
         <div className='lg:flex lg:gap-10'>
             <div className='whyChoose__haodapay__firsthalf flex justify-center'>
                 <div className=''>
                    <div className='flex justify-center lg:justify-start text-center'><div className='whyChoose__haodapay__bigHeading'>Why Choose HaodaPay for Your UPI Payments?</div></div>
                    <div className='flex justify-center whyChooseHaodapay__Img'><img src='\images\payment__housing.png' alt='image' /></div>
                </div>
                 
             </div>
             <div className='whyChoose__haodapay__secondhalf   whyChoose__haodapay__text'>
                 <div className='flex justify-center sm:text-left text-center lg:justify-start'><div className='grid gap-8'>
                 <div>
                    <div className='whyChoose__haodapay__subHeading'>1. Unmatched Security</div>
                    <div className='sm:mt-4 mt-2'>Our cutting-edge encryption technology ensures that your financial data is always protected.</div>
                 </div>
                 <div>
                    <div className='whyChoose__haodapay__subHeading'>2. Seamless Transactions</div>
                    <div className='sm:mt-4 mt-2'>HaodaPay enables quick and easy UPI payments, making your transactions hassle-free and efficient.</div>
                 </div>
                 <div>
                    <div className='whyChoose__haodapay__subHeading'>3. User-Friendly Interface</div>
                    <div className='sm:mt-4 mt-2'>Navigate our intuitive app with ease, making transactions quick and effortless.</div>
                 </div>
                 <div>
                    <div className='whyChoose__haodapay__subHeading'>4. Reliable Support</div>
                    <div className='sm:mt-4 mt-2'>Our dedicated customer support team is always here to assist you with any queries or issues.</div>
                 </div>
                 </div></div>
             </div>
         </div> 
         </div>
       </motion.div> 
       </div>

       {/* fourth__page */}
       
       <div className='joinHaodapay__revoluiton__bgColor pt-10 md:pt-20'>
           <div className='mx-auto container'>
              <div className='flex justify-center'><div className='lg:flex joinHaodapay__revoluiton__page'>
              <div className='flex joinHaodapay__revoluiton__page1 justify-center items-center lg:my-20'>
                <div className='grid gap-y-5 lg:pl-5'>
                <div className='joinHaodapay__revoluiton__heading text-center lg:text-left'>Experience the future of digital payments with HaodaPay's UPI payment solution</div>
                <div className='joinHaodapay__revoluiton__text text-center lg:text-left'>Embrace convenience, security, and cost-effectiveness in your financial transactions, and unlock a world of possibilities with HaodaPay.</div>
                </div>
              </div>
              <div className='lg:relative flex justify-center mb-20 lg:mb-0 justify-self-center lg:items-start joinHaodapay__revoluiton__page2 lg:pt-20 pt-10'>
                 <a href='/contact'><div class="Join__btn flex justify-center lg:justify-start items-start">
                     <button className='HaodaPay_Revolution_btn flex items-center justify-center lg:justify-start px-5 py-3'>Join the HaodaPay Revolution
                         <span className='join__arrow flex justify-center text-xs items-center mt-1'><FaArrowRight className='right__arrow block' /></span>
                     </button>
                  </div></a>
                <div className='joinHaodapay__revoluiton__img lg:flex hidden justify-center lg:justify-end'><img className='joinHaodapay__revoluiton' src='/images/Housing society image 1.png' alt='image' /></div>
              </div>
              </div> </div>
           </div>
       </div>

       <Nextstep />
        <Footer />
    </>
  )
}

export default UPIpayments