import React, { useState } from 'react'
import "./healthcare.css"
import Navbar from '../nav/navbar'
import Nextstep from '../nextStep/nextstep'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css'
import { FaArrowLeft } from "react-icons/fa6";
import { FaArrowRight } from "react-icons/fa6";
import Footer from '../footer/footer';
import { easeInOut, motion } from 'framer-motion'
import { Helmet } from "react-helmet"


const Healthcare = () => {

    const [tabIndex, setTabIndex] = useState(0);

    const tabContents = [
      {
        title: 'Mobile Payment Solutions',
        slides: [
          { 
              content: 'Haoda Pay offers mobile payment solutions, allowing patients to conveniently pay for healthcare services using their smartphones or tablets, ensuring secure transactions on the go.',
              image: '/images/tap__img.png', 
          }
        ],
      },
      {
        title: 'Online Payment Portals',
        slides: [
          { 
              content: 'Haoda Pay provides user-friendly online payment portals for healthcare services, utilities, and subscriptions, ensuring a seamless transaction experience, reducing administrative hassle and saving time.Pay\'s online payment solutions.',
              image: '/images/tap__img.png', 
          }
        ],
      },
      {
        title: 'Billing and Invoicing Solutions',
        slides: [
          { 
              content: 'Haoda Pay\'s Billing and Invoicing Solutions provide streamlined financial management, including invoicing, payment tracking, and expense management. With secure processing, customizable templates, and intuitive reporting, these solutions cater to freelancers, small businesses, and large enterprises, ensuring efficiency and reliability.',
              image: '/images/tap__img.png', 
          }
        ],
      },
      {
        title: 'Integration with Insurance Providers',
        slides: [
          { 
              content: 'Our solutions offer exceptional efficiency by integrating with top insurance providers, eliminating manual tasks, enabling instant claim processing, automated data interchange, and informed decision-making.',
              image: '/images/tap__img.png', 
          }
        ],
      },
      {
        title: 'Patient Financing Options',
        slides: [
          { 
              content: 'Haoda Pay provides flexible patient financing options, offering transparent terms and competitive rates to bridge the gap between affordability and quality care in healthcare.',
              image: '/images/tap__img.png', 
          }
        ],
      },
      {
         title: 'Security and Compliance',
         slides: [
          { 
              content: 'Haoda Pay\'s Security and Compliance solutions prioritize data protection, industry regulations, and confidentiality, ensuring secure transactions and unauthorized access, thereby providing peace of mind for financial information.',
              image: '/images/tap__img.png', 
          }
         ],
      }
    ];
  
    const handleNextTab = () => {
      if (tabIndex < tabContents.length - 1) {
        setTabIndex(tabIndex + 1);
      }
    };
  
    const handlePrevTab = () => {
      if (tabIndex > 0) {
        setTabIndex(tabIndex - 1);
      }
    };

  return (
    <div>

<Helmet>
        <title>Payment Solutions for Healthcare | Healthcare Payment Solutions in India - Haoda pay</title>
        <meta name='tag' content='payment solutions healthcare'></meta>
        <meta name='description' content='Haoda Pay helps in offering payment solutions in Healthcare in India with simplify payments, ensure transparency, and provide real-time updates for peace of mind.'/>
    </Helmet>


        <Navbar />

    {/* Healthcare Banner section */}

      <div className='healthcare__bannerBg haodapay__certificateBannerTop mb-20 h-fit'>
              <div className='clubAssociation__BgVideo'>
                <video loop autoPlay muted playsinline className='w-full h-full'>
                  <source src='\images\Web 800_1.mp4' type='video/mp4' className='w-full h-full' />
                </video>
              </div>          

              <div className='healthcareBanner__bannerContent container mx-auto 2xl:px-20 lg:flex justify-between items-center flex-wrap lg:flex-nowrap py-14 px-5 xl:px-10'>
                  <div className='lg:w-3/5 2xl:w-1/2 lg:pl-5 2xl:pl-0'>
                      <span className='block clubAssociation__BannerTitle text-center lg:text-left'>Haoda Pay : Your reliable partner for secure healthcare payments!</span>
                  </div>
                  <div className='lg:w-2/5 flex justify-center lg:justify-end items-center mt-10 lg:mt-0 lg:pl-5 2xl:pl-0'>
                    <div className='clubAssociation__img'>
                        <img src='\images\Healthcare__bannerImg.jpg' alt='img' />
                    </div>
                  </div>
              </div>
        </div>

    {/* Tab section */}

        <motion.div className='flex justify-center items-center'
          initial={{ opacity: 0,
            y: "70px"
           }}
          whileInView={{ opacity: 1, y:"0px" }}
          transition={{ 
            duration: 1.2,
            ease: easeInOut,
            delay: 0.4 
           }}
          viewport={{once:true}}

        >
            <span className='tab__heading lg:w-4/5 text-center'>Let's delve into the types of healthcare payment solutions <span className='tab__headingHaoda'>Haoda</span><span className='tab__headingPay'> Pay</span> offers</span>
        </motion.div>

        <motion.div className='container mx-auto px-7 lg:px-7 xl:px-10 2xl:px-20 my-20'
          initial={{ opacity: 0,
            y: "70px"
           }}
          whileInView={{ opacity: 1, y:"0px" }}
          transition={{ 
            duration: 1.2,
            ease: easeInOut,
            delay: 0.4 
           }}
          viewport={{once:true}}

        >
            <div className='healthcare__tabContainer lg:px-7 py-10 xl:px-10 2xl:px-20'>
                <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
                  <TabList className="tab__list flex justify-center items-center flex-wrap 2xl:flex-nowrap gap-y-5 md:gap-y-10 2xl:gap-y-0 px-5">
                    {tabContents.map((tab, index) => (
                      <Tab key={index} className={tabIndex === index ? "active-tab" : "react-tabs__tab" }>{tab.title}</Tab>
                    ))}
                  </TabList>
          
                  {tabContents.map((tab, index) => (
                    <TabPanel key={index} className="healthcare__tabContent" >
                      <Swiper slidesPerView={1} pagination={{ clickable: true }}>
                        {tab.slides.map((slide, slideIndex) => (
                          <SwiperSlide key={slideIndex}>
                            <div className='healthcare__tabImg flex justify-center items-center flex-wrap md:flex-nowrap  py-10'>
                                <div className='md:w-1/2'>
                                    <span className='healthcare__tabImgContent'> {slide.content} </span>
                                </div>
                                <div className='md:w-1/2'>
                                    <img src={slide.image} alt='img' />
                                </div>
                            </div>
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    </TabPanel>
                  ))}
                </Tabs>
    
                <div className='housing__tabButtons'>
                  <button 
                    onClick={handlePrevTab} 
                    disabled={tabIndex === 0} 
                    style={{ padding: '10px 20px', cursor: tabIndex === 0 ? 'not-allowed' : 'pointer' }}
                    className='healthcare__PreBtn'
                  >
                     <FaArrowLeft />
                  </button>
          
                  <button 
                    onClick={handleNextTab} 
                    disabled={tabIndex === tabContents.length - 1} 
                    style={{ padding: '10px 20px', cursor: tabIndex === tabContents.length - 1 ? 'not-allowed' : 'pointer' }}
                    className='healthcare__nextBtn'
                  >
                     <FaArrowRight />
                  </button>
                </div>
            </div>
        </motion.div>

    {/* healthcare service */}

        <motion.div className='my-20 container mx-auto px-5 xl:px-10 2xl:px-20'
          initial={{ opacity: 0,
            y: "70px"
           }}
          whileInView={{ opacity: 1, y:"0px" }}
          transition={{ 
            duration: 1.2,
            ease: easeInOut,
            delay: 0.4 
           }}
          viewport={{once:true}}

        >
            <div className='flex justify-center items-center'>
                <span  className='healthCare__serviceTitle mb-14 text-center inline-block lg:w-4/5'>Discover how <span className='healthCare__serviceHaodaTitle'>Haoda</span> <span className='healthCare__servicePayTitle'>Pay</span> can optimize your business's payment solutions in the digital era.</span>
            </div>
            <div className='grid sm:grid-cols-2 lg:grid-cols-3 gap-10'>
                <div className='flex flex-col justify-center items-center sm:inline-block'>
                    <span className='block'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="57" height="56" viewBox="0 0 57 56" fill="none">
                          <g clip-path="url(#clip0_2090_2261)">
                            <path d="M51.4688 12.5596C50.5627 12.5596 49.8281 13.2942 49.8281 14.2003C49.8281 15.1063 50.5627 15.8409 51.4688 15.8409H54.8594C55.7654 15.8409 56.5 15.1063 56.5 14.2003C56.5 13.2942 55.7654 12.5596 54.8594 12.5596H51.4688Z" fill="#392675"/>
                            <path d="M50.6436 7.88622L53.8297 6.72651C54.6812 6.41665 55.1201 5.47515 54.8103 4.62367C54.5004 3.77218 53.5588 3.33315 52.7074 3.64312L49.5213 4.80282C48.6698 5.11268 48.2309 6.05418 48.5408 6.90567C48.8508 7.75737 49.7927 8.19607 50.6436 7.88622Z" fill="#392675"/>
                            <path d="M49.5213 23.5976L52.7074 24.7573C53.5588 25.0672 54.5004 24.6281 54.8103 23.7767C55.1201 22.9252 54.6812 21.9837 53.8297 21.6739L50.6436 20.5142C49.7922 20.2043 48.8508 20.6432 48.5408 21.4947C48.2309 22.3462 48.6698 23.2877 49.5213 23.5976Z" fill="#392675"/>
                            <path d="M2.14062 15.8409H5.53125C6.43731 15.8409 7.17188 15.1063 7.17188 14.2003C7.17188 13.2942 6.43731 12.5596 5.53125 12.5596H2.14062C1.23456 12.5596 0.5 13.2942 0.5 14.2003C0.5 15.1063 1.23456 15.8409 2.14062 15.8409Z" fill="#392675"/>
                            <path d="M3.16991 6.72654L6.356 7.88624C7.20738 8.19621 8.14898 7.75707 8.45884 6.9057C8.7687 6.05421 8.32978 5.11271 7.4783 4.80285L4.29209 3.64326C3.44094 3.3334 2.49922 3.77232 2.18925 4.62381C1.87928 5.47529 2.31842 6.41668 3.16991 6.72654Z" fill="#392675"/>
                            <path d="M6.35586 20.5143L3.16977 21.674C2.31828 21.9839 1.87936 22.9254 2.18922 23.7768C2.49919 24.6286 3.44102 25.0673 4.29206 24.7574L7.47816 23.5977C8.32964 23.2878 8.76856 22.3463 8.4587 21.4948C8.14874 20.6434 7.20702 20.2041 6.35586 20.5143Z" fill="#392675"/>
                            <path d="M0.980535 39.5095C0.339822 40.1502 0.339822 41.189 0.980535 41.8297L14.6513 55.5006C15.2919 56.1413 16.3308 56.1413 16.9715 55.5006L20.4518 52.0203L4.46082 36.0292L0.980535 39.5095Z" fill="#392675"/>
                            <path d="M55.264 36.039C53.0142 33.7892 49.4201 33.6262 46.9756 35.6639L40.2174 41.2955H24.9892C24.1088 41.2955 23.3486 40.6284 23.2885 39.7512C23.2239 38.7854 23.9852 37.976 24.9444 37.976H34.6579C37.7399 37.976 40.2382 35.4777 40.2382 32.3958V31.1161C40.2382 30.8141 39.9933 30.5692 39.6913 30.5692H18.2714C14.391 30.5692 10.6697 32.1106 7.92578 34.8545L21.6883 48.6159H43.0371C43.1628 48.6159 43.2853 48.5733 43.3827 48.4924L56.2998 37.9246C56.5502 37.7201 56.5688 37.3438 56.3402 37.1153L55.264 36.039Z" fill="#392675"/>
                            <path d="M28.4998 28.3817C36.3195 28.3817 42.6813 22.02 42.6813 14.2003C42.6813 6.38055 36.3195 0.0187988 28.4998 0.0187988C20.6801 0.0187988 14.3184 6.38055 14.3184 14.2003C14.3184 22.02 20.6801 28.3817 28.4998 28.3817ZM28.024 22.9939C26.7817 22.6334 26.4512 20.9641 27.4597 20.1557C28.3831 19.3735 29.8902 19.9053 30.1087 21.1039C30.3693 22.2948 29.1713 23.3666 28.024 22.9939ZM28.4998 6.58803C31.2064 6.58803 33.4083 8.78995 33.4083 11.4965C33.4083 13.6281 32.0427 15.4466 30.1404 16.1232V16.5797C30.1404 17.4857 29.4059 18.2203 28.4998 18.2203C27.5938 18.2203 26.8592 17.4857 26.8592 16.5797V14.7644C26.8592 13.8583 27.5938 13.1238 28.4998 13.1238C29.3971 13.1238 30.1271 12.3937 30.1271 11.4964C30.1271 10.5991 29.3971 9.86914 28.4998 9.86914C27.6025 9.86914 26.8725 10.5991 26.8725 11.4964C26.8725 12.4025 26.138 13.137 25.2319 13.137C24.3259 13.137 23.5913 12.4025 23.5913 11.4964C23.5912 8.79006 25.7932 6.58803 28.4998 6.58803Z" fill="#F4B936"/>
                          </g>
                          <defs>
                            <clipPath id="clip0_2090_2261">
                              <rect width="56" height="56" fill="white" transform="translate(0.5)"/>
                            </clipPath>
                          </defs>
                        </svg>
                    </span>
                        <span className='healthCare__serviceContentTitle sm:text-left text-center inline-block my-5'>Understand Your Business Needs</span>
                        <span className='healthCare__servicePara inline-block text-center sm:text-left'>Assess your business's requirements, including transaction volume, industry regulations, customer preferences, and scalability.</span>
                </div>

                <div className='flex flex-col justify-center items-center sm:inline-block'>
                    <span className='block'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="57" height="56" viewBox="0 0 57 56" fill="none">
                           <g clip-path="url(#clip0_2090_2315)">
                             <path d="M10.3438 9.84375H16.9062V14.2188H10.3438V9.84375Z" fill="#392675"/>
                             <path d="M50.4844 0H6.51562C3.19861 0 0.5 2.69861 0.5 6.01562V30.0781C0.5 31.2942 0.863781 32.4263 1.48667 33.3736C4.2753 30.4679 8.19552 28.6562 12.5312 28.6562C18.0967 28.6562 22.9777 31.6411 25.6588 36.0938H50.4844C53.8014 36.0938 56.5 33.3951 56.5 30.0781V6.01562C56.5 2.69861 53.8014 0 50.4844 0ZM8.70312 17.5C7.79706 17.5 7.0625 16.7654 7.0625 15.8594V8.20312C7.0625 7.29706 7.79706 6.5625 8.70312 6.5625H18.5469C19.4529 6.5625 20.1875 7.29706 20.1875 8.20312V15.8594C20.1875 16.7654 19.4529 17.5 18.5469 17.5H8.70312ZM19.8594 26.25H17.6719C16.7658 26.25 16.0312 25.5154 16.0312 24.6094C16.0312 23.7033 16.7658 22.9688 17.6719 22.9688H19.8594C20.7654 22.9688 21.5 23.7033 21.5 24.6094C21.5 25.5154 20.7654 26.25 19.8594 26.25ZM28.6094 26.25H26.4219C25.5158 26.25 24.7812 25.5154 24.7812 24.6094C24.7812 23.7033 25.5158 22.9688 26.4219 22.9688H28.6094C29.5154 22.9688 30.25 23.7033 30.25 24.6094C30.25 25.5154 29.5154 26.25 28.6094 26.25ZM37.3594 26.25H35.1719C34.2658 26.25 33.5312 25.5154 33.5312 24.6094C33.5312 23.7033 34.2658 22.9688 35.1719 22.9688H37.3594C38.2654 22.9688 39 23.7033 39 24.6094C39 25.5154 38.2654 26.25 37.3594 26.25ZM46.1094 26.25H43.9219C43.0158 26.25 42.2812 25.5154 42.2812 24.6094C42.2812 23.7033 43.0158 22.9688 43.9219 22.9688H46.1094C47.0154 22.9688 47.75 23.7033 47.75 24.6094C47.75 25.5154 47.0154 26.25 46.1094 26.25ZM47.2031 16.4062H37.1406C36.2346 16.4062 35.5 15.6717 35.5 14.7656C35.5 13.8596 36.2346 13.125 37.1406 13.125H47.2031C48.1092 13.125 48.8438 13.8596 48.8438 14.7656C48.8438 15.6717 48.1092 16.4062 47.2031 16.4062ZM47.2031 9.84375H32.7656C31.8596 9.84375 31.125 9.10919 31.125 8.20312C31.125 7.29706 31.8596 6.5625 32.7656 6.5625H47.2031C48.1092 6.5625 48.8438 7.29706 48.8438 8.20312C48.8438 9.10919 48.1092 9.84375 47.2031 9.84375Z" fill="#392675"/>
                             <path d="M12.5312 31.9375C5.88661 31.9375 0.5 37.3241 0.5 43.9688C0.5 50.6134 5.88661 56 12.5312 56C19.1759 56 24.5625 50.6134 24.5625 43.9688C24.5625 37.3241 19.1759 31.9375 12.5312 31.9375ZM19.4336 41.8476L11.7773 49.5039C11.1366 50.1446 10.0979 50.1446 9.45716 49.5039L5.62903 45.6758C4.98831 45.035 4.98831 43.9963 5.62903 43.3556C6.26975 42.7149 7.30848 42.7149 7.9492 43.3556L10.6172 46.0236L17.1133 39.5275C17.754 38.8868 18.7927 38.8868 19.4335 39.5275C20.0742 40.1682 20.0743 41.2069 19.4336 41.8476Z" fill="#F4B936"/>
                           </g>
                           <defs>
                             <clipPath id="clip0_2090_2315">
                               <rect width="56" height="56" fill="white" transform="translate(0.5)"/>
                             </clipPath>
                           </defs>
                         </svg>
                    </span>
                    <span className='healthCare__serviceContentTitle inline-block my-5'>Evaluate Payment Methods</span>
                    <span className='healthCare__servicePara inline-block text-center sm:text-left'>Determine customer-preferred payment methods such as credit/debit cards, mobile payments, or e-wallets to enhance satisfaction and sales.</span>
                </div>

                <div className='flex flex-col justify-center items-center sm:inline-block'>
                    <span className='block'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="57" height="56" viewBox="0 0 57 56" fill="none">
                          <path d="M46.7406 20.7117L44.1959 20.3329C43.743 18.4552 43.0018 16.66 41.9971 14.9964L43.5124 12.9376C44.2206 11.9906 44.1218 10.64 43.2818 9.79994L40.6383 7.15645C39.79 6.30819 38.4724 6.20937 37.5006 6.92583L35.4336 8.44939C34.7171 8.02939 33.8524 7.59289 32.9465 7.2141C31.9665 6.79409 31.003 6.46466 30.1053 6.2341L29.7265 3.70586C29.5536 2.52822 28.5242 1.64703 27.3383 1.64703H23.6077C22.43 1.64703 21.4088 2.52822 21.2194 3.69761L20.8406 6.22586C18.9465 6.69528 17.1512 7.43642 15.4959 8.44939L13.4288 6.91759C12.4653 6.21761 11.1477 6.31643 10.2994 7.15645L7.6559 9.79994C6.80769 10.6564 6.70887 11.9741 7.42533 12.9376L8.94885 14.9964C7.94416 16.6435 7.20296 18.4388 6.7418 20.3329L4.19711 20.7117C3.00299 20.9012 2.14648 21.9058 2.14648 23.1082V26.8306C2.14648 28.0165 3.02767 29.0459 4.19711 29.2188L6.72531 29.5976C7.19473 31.5 7.93592 33.287 8.94885 34.9423L7.42533 37.0094C6.71711 37.9564 6.82417 39.307 7.6559 40.1388L10.2994 42.7823C11.1477 43.6305 12.4653 43.7294 13.4371 43.0211L15.4959 41.4976C17.1595 42.5106 18.9465 43.2517 20.8406 43.7129L21.2194 46.2411C21.4088 47.427 22.4053 48.2917 23.6077 48.2917H26.8524C25.3948 45.8788 24.5465 43.0459 24.5465 40.0235C24.5465 38.0882 24.8924 36.2353 25.5265 34.5223H25.4688C20.1983 34.5223 15.9159 30.2317 15.9159 24.9694C15.9159 19.707 20.1983 15.4247 25.4688 15.4247C30.7312 15.4247 35.0218 19.707 35.0218 24.9694V25.027C36.743 24.3929 38.5959 24.047 40.5312 24.047C43.5536 24.047 46.3783 24.8953 48.7912 26.3529V23.1082C48.7912 21.9058 47.9265 20.9012 46.7406 20.7117Z" fill="#392675"/>
                          <path d="M40.531 25.6941C32.6251 25.6941 26.1934 32.1176 26.1934 40.0235C26.1934 47.9211 32.6251 54.3529 40.531 54.3529C44.3522 54.3529 47.951 52.8541 50.6604 50.1529C53.3616 47.4517 54.8522 43.8529 54.8522 40.0235C54.8522 32.1176 48.4286 25.6941 40.531 25.6941ZM42.4745 41.3658L43.051 44.2317C43.1334 44.6435 42.8122 45.0305 42.3922 45.0305H38.6616C38.2333 45.0305 37.9204 44.6435 38.0028 44.2317L38.5793 41.3658C38.6451 41.0446 38.5463 40.7235 38.3157 40.5011C37.7063 39.9247 37.3357 39.1011 37.3357 38.1953C37.3357 36.1858 39.1887 34.6129 41.2969 35.0988C42.4663 35.3706 43.4051 36.3341 43.6439 37.5117C43.8828 38.6894 43.4957 39.7764 42.7463 40.4929C42.5075 40.7153 42.4086 41.0446 42.4745 41.3658Z" fill="#F4B936"/>
                        </svg>
                    </span>
                    <span className='healthCare__serviceContentTitle inline-block my-5'>Consider Security Features</span>
                    <span className='healthCare__servicePara inline-block text-center sm:text-left'>Prioritize security with encryption, tokenization, and fraud detection to protect customer data and prevent cyber threats.</span>
                </div>

                <div className='flex flex-col justify-center items-center sm:inline-block'>
                    <span className='block'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="57" height="56" viewBox="0 0 57 56" fill="none">
                          <g clip-path="url(#clip0_2090_2339)">
                            <path d="M40.7782 31.3721C42.252 23.4183 36.0289 16.545 28.501 16.571C20.2345 16.5828 14.5013 24.3409 16.3644 31.9757L20.7054 31.9743C20.3587 31.0603 20.1673 30.0705 20.1673 29.0364C20.1673 24.4549 23.8946 20.7277 28.476 20.7277C33.0574 20.7277 36.7848 24.4549 36.7848 29.0364C36.7848 30.0686 36.594 31.0568 36.2485 31.9693L40.6445 31.9679C40.7152 31.774 40.7411 31.5703 40.7782 31.3721Z" fill="#392675"/>
                            <path d="M56.0328 22.7894C55.9598 22.374 55.6322 22.0507 55.2158 21.9841L52.0897 21.4839C51.7218 21.425 51.4184 21.1642 51.306 20.809C50.8895 19.4929 50.357 18.2056 49.7073 16.9635C49.5355 16.635 49.5636 16.2378 49.7787 15.9359L51.6167 13.3562C51.859 13.0159 51.8616 12.5597 51.6228 12.2171C50.6442 10.8139 49.5289 9.47702 48.277 8.22509C47.0084 6.95654 45.6527 5.8282 44.2289 4.84029C43.8841 4.601 43.4254 4.60592 43.0855 4.8521L40.5155 6.71298C40.2143 6.93106 39.8159 6.96157 39.4857 6.79074C38.2566 6.15521 36.984 5.63353 35.6834 5.22548C35.3297 5.11447 35.0688 4.81415 35.0074 4.44854L34.481 1.31364C34.4118 0.901653 34.0911 0.57727 33.6799 0.503994C31.9958 0.203672 30.2619 0.0470581 28.4913 0.0470581C26.6971 0.0470581 24.9405 0.207937 23.2351 0.516134C22.8223 0.590722 22.5016 0.918276 22.4353 1.33256L21.935 4.45947C21.8761 4.82738 21.6152 5.13077 21.26 5.2432C19.9511 5.65737 18.6706 6.1866 17.4348 6.83143C17.1065 7.0027 16.7099 6.97448 16.4082 6.75968L13.8372 4.928C13.497 4.68564 13.0408 4.68291 12.6982 4.92188C11.295 5.90039 9.95808 7.0156 8.70615 8.26753C7.4376 9.53619 6.30926 10.8919 5.32135 12.3156C5.08205 12.6605 5.08697 13.1192 5.33316 13.4591L7.17785 16.0065C7.39615 16.308 7.42645 16.7067 7.25518 17.0371C6.61363 18.2743 6.08757 19.5557 5.67657 20.8656C5.56556 21.2193 5.26524 21.4802 4.89963 21.5415L1.76517 22.0679C1.35198 22.1372 1.02727 22.4593 0.955632 22.8722C0.674668 24.4929 0.519258 26.4744 0.500009 30.9916C0.497712 31.5389 0.940539 31.9844 1.48781 31.9842H13.0197C11.1974 22.176 18.6968 13.3347 28.5001 13.3083C38.4909 13.3111 45.7648 22.4022 43.9845 31.9709L55.5129 31.9673C56.0599 31.9671 56.5024 31.5216 56.5 30.9745C56.4804 26.4313 56.321 24.4295 56.0328 22.7894Z" fill="#392675"/>
                            <path d="M33.5216 29.0366C33.5216 26.2542 31.258 23.9906 28.4757 23.9906C25.6934 23.9906 23.4297 26.2542 23.4297 29.0366C23.4297 30.1316 23.7813 31.1453 24.3762 31.9732L32.5772 31.9707C33.1707 31.1432 33.5216 30.1304 33.5216 29.0366Z" fill="#392675"/>
                            <path d="M5.91025 55.8889C8.45749 55.4759 10.1877 53.0762 9.77476 50.529C9.36184 47.9818 6.96215 46.2516 4.41491 46.6645C1.86768 47.0774 0.137477 49.4771 0.550403 52.0243C0.963329 54.5716 3.36301 56.3018 5.91025 55.8889Z" fill="#F4B936"/>
                            <path d="M14.3493 35.1856V49.8012H12.793C12.9045 50.3309 12.964 50.8793 12.964 51.4417C12.964 52.0041 12.9045 52.5526 12.793 53.0822H15.9898C16.8958 53.0822 17.6303 52.3477 17.6303 51.4417V35.1845L14.3493 35.1856Z" fill="#392675"/>
                            <path d="M29.2345 53.1373C31.7817 52.7244 33.5119 50.3247 33.099 47.7775C32.6861 45.2303 30.2864 43.5001 27.7391 43.913C25.1919 44.3259 23.4617 46.7256 23.8746 49.2728C24.2875 51.8201 26.6872 53.5503 29.2345 53.1373Z" fill="#F4B936"/>
                            <path d="M28.4979 40.5736C29.0603 40.5736 29.6088 40.6331 30.1384 40.7446V35.1779H26.8574V40.7446C27.3871 40.6331 27.9356 40.5736 28.4979 40.5736Z" fill="#392675"/>
                            <path d="M51.8214 55.9532C54.4022 55.9532 56.4943 53.8611 56.4943 51.2803C56.4943 48.6995 54.4022 46.6074 51.8214 46.6074C49.2406 46.6074 47.1484 48.6995 47.1484 51.2803C47.1484 53.8611 49.2406 55.9532 51.8214 55.9532Z" fill="#F4B936"/>
                            <path d="M44.0295 51.4416C44.0295 50.8794 44.089 50.3308 44.2006 49.8011H42.6443V35.1768L39.3633 35.1777V51.4416C39.3633 52.3476 40.0978 53.0821 41.0038 53.0821H44.2006C44.089 52.5525 44.0295 52.004 44.0295 51.4416Z" fill="#392675"/>
                          </g>
                          <defs>
                            <clipPath id="clip0_2090_2339">
                              <rect width="56" height="56" fill="white" transform="translate(0.5)"/>
                            </clipPath>
                          </defs>
                        </svg>
                    </span>
                    <span className='healthCare__serviceContentTitle inline-block my-5'>Assess Integration Capabilities</span>
                    <span className='healthCare__servicePara inline-block text-center sm:text-left'>Ensure seamless integration with existing systems like e-commerce platforms and POS systems to streamline operations.</span>
                </div>

                <div className='flex flex-col justify-center items-center sm:inline-block'>
                    <span className='block'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="57" height="56" viewBox="0 0 57 56" fill="none">
                          <g clip-path="url(#clip0_2090_2282)">
                            <path d="M13.4071 6.76538V11.9253C13.4071 12.8313 12.6726 13.5658 11.7667 13.5658H7.09961V16.9229H21.823C20.7424 15.5136 20.0982 13.7531 20.0982 11.8442C20.0982 9.93526 20.7425 8.17474 21.823 6.76549L13.4071 6.76538Z" fill="#392675"/>
                            <path d="M28.5009 56C31.9564 56 34.7576 53.1988 34.7576 49.7433C34.7576 46.2878 31.9564 43.4865 28.5009 43.4865C25.0454 43.4865 22.2441 46.2878 22.2441 49.7433C22.2441 53.1988 25.0454 56 28.5009 56Z" fill="#F4B936"/>
                            <path d="M26.8243 27.0397V33.7659H8.33575C7.42979 33.7659 6.69531 34.5004 6.69531 35.4064V40.3587C7.24902 40.2583 7.81923 40.2056 8.40148 40.2056C8.93779 40.2056 9.46372 40.2509 9.97619 40.3363V37.0467H26.8243V40.3533C27.3688 40.2563 27.9292 40.2055 28.5011 40.2055C29.0477 40.2055 29.5835 40.2524 30.1051 40.3411V37.0467H47.0261V40.3363C47.5385 40.2508 48.0645 40.2056 48.6008 40.2056C49.183 40.2056 49.7531 40.2583 50.3069 40.3587V35.4064C50.3069 34.5004 49.5725 33.7659 48.6665 33.7659H30.1051V27.0397H26.8243Z" fill="#392675"/>
                            <path d="M48.6005 56C52.056 56 54.8572 53.1988 54.8572 49.7433C54.8572 46.2878 52.056 43.4865 48.6005 43.4865C45.145 43.4865 42.3438 46.2878 42.3438 49.7433C42.3438 53.1988 45.145 56 48.6005 56Z" fill="#F4B936"/>
                            <path d="M13.4066 0V3.55516H51.7639C52.6699 3.55516 53.4044 4.28964 53.4044 5.1956V18.634C53.4044 19.5399 52.6699 20.2744 51.7639 20.2744H5.45869C4.55273 20.2744 3.81825 19.5399 3.81825 18.634V13.6364H0.503906V21.7967C0.503906 22.9195 1.41402 23.8295 2.53663 23.8295H54.4647C55.5875 23.8295 56.4975 22.9193 56.4975 21.7967V2.03272C56.4976 0.910116 55.5875 0 54.4647 0L13.4066 0Z" fill="#392675"/>
                            <path d="M10.1255 9.5337V2.3418L2.43555 10.2848H9.37428C9.7892 10.2848 10.1255 9.94851 10.1255 9.5337Z" fill="#392675"/>
                            <path d="M8.40128 56C11.8568 56 14.658 53.1988 14.658 49.7433C14.658 46.2878 11.8568 43.4865 8.40128 43.4865C4.94577 43.4865 2.14453 46.2878 2.14453 49.7433C2.14453 53.1988 4.94577 56 8.40128 56Z" fill="#F4B936"/>
                            <path d="M28.6432 16.9229C31.3625 16.8271 33.5455 14.5864 33.5455 11.8442C33.5455 9.10204 31.3625 6.8613 28.6432 6.7655H28.2813C25.562 6.8613 23.3789 9.10204 23.3789 11.8442C23.3789 14.5864 25.562 16.8271 28.2813 16.9229H28.6432Z" fill="#392675"/>
                            <path d="M35.1016 6.76538C36.1821 8.17463 36.8263 9.93515 36.8263 11.8441C36.8263 13.753 36.1822 15.5135 35.1016 16.9228H50.1236V6.76538H35.1016ZM43.4791 13.5785C42.5731 13.5785 41.8386 12.844 41.8386 11.938C41.8386 11.0321 42.5731 10.2976 43.4791 10.2976C44.385 10.2976 45.1195 11.0321 45.1195 11.938C45.1195 12.844 44.385 13.5785 43.4791 13.5785Z" fill="#392675"/>
                          </g>
                          <defs>
                            <clipPath id="clip0_2090_2282">
                              <rect width="56" height="56" fill="white" transform="translate(0.5)"/>
                            </clipPath>
                          </defs>
                        </svg>                    
                    </span>
                    <span className='healthCare__serviceContentTitle inline-block my-5'>Evaluate Fees and Pricing Structure</span>
                    <span className='healthCare__servicePara inline-block text-center sm:text-left'>Compare transaction fees, subscription fees, and other charges to align with your budget and business model.</span>
                </div>

                <div className='flex flex-col justify-center items-center sm:inline-block'>
                    <span className='block'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="57" height="56" viewBox="0 0 57 56" fill="none">
                          <g clip-path="url(#clip0_2090_2295)">
                            <path d="M0.5 33.3594V39.9219C0.5 43.2443 3.19325 45.9375 6.51562 45.9375H9.79688C10.7029 45.9375 11.4375 45.2029 11.4375 44.2969V27.3438H6.51562C3.19325 27.3438 0.5 30.037 0.5 33.3594Z" fill="#392675"/>
                            <path d="M38.8906 32.2656V23.5156C38.8906 21.4048 37.1733 19.6875 35.0625 19.6875H21.9375C19.8267 19.6875 18.1094 21.4048 18.1094 23.5156V32.2656C18.1094 34.3765 19.8267 36.0938 21.9375 36.0938H25.7185L31.8247 41.1822C32.1253 41.4328 32.4984 41.5625 32.8752 41.5625C33.1113 41.5625 33.3488 41.5115 33.5708 41.4076C34.1474 41.1376 34.5156 40.5584 34.5156 39.9219V36.0938H35.0625C37.1733 36.0938 38.8906 34.3765 38.8906 32.2656ZM30.6875 29.5312H26.3125C25.4064 29.5312 24.6719 28.7967 24.6719 27.8906C24.6719 26.9846 25.4064 26.25 26.3125 26.25H30.6875C31.5936 26.25 32.3281 26.9846 32.3281 27.8906C32.3281 28.7967 31.5936 29.5312 30.6875 29.5312Z" fill="#F4B936"/>
                            <path d="M22.4844 52.7188C22.4844 54.531 23.9534 56 25.7656 56H31.2344C33.0466 56 34.5156 54.531 34.5156 52.7188C34.5156 50.9065 33.0466 49.4375 31.2344 49.4375H25.7656C23.9534 49.4375 22.4844 50.9065 22.4844 52.7188Z" fill="#392675"/>
                            <path d="M46.6567 49.2188V49.5469C46.6567 50.4515 45.9208 51.1875 45.0161 51.1875H37.6151C37.7332 51.6791 37.7974 52.1915 37.7974 52.7188C37.7974 53.3248 37.713 53.9112 37.5586 54.4688H45.0161C47.73 54.4688 49.938 52.2608 49.938 49.5469V49.2188H46.6567Z" fill="#392675"/>
                            <path d="M50.4844 27.3438H45.5625V44.2969C45.5625 45.2029 46.2971 45.9375 47.2031 45.9375H50.4844C53.8068 45.9375 56.5 43.2443 56.5 39.9219V33.3594C56.5 30.037 53.8068 27.3438 50.4844 27.3438Z" fill="#392675"/>
                            <path d="M45.2054 6.91961C40.7432 2.45744 34.8105 0 28.5 0C22.1895 0 16.2568 2.45744 11.7946 6.91961C7.33244 11.3818 4.875 17.3145 4.875 23.625V24.0625H11.4375V23.625C11.4375 14.2167 19.0917 6.5625 28.5 6.5625C37.9083 6.5625 45.5625 14.2167 45.5625 23.625V24.0625H52.125V23.625C52.125 17.3145 49.6676 11.3818 45.2054 6.91961Z" fill="#392675"/>
                          </g>
                          <defs>
                            <clipPath id="clip0_2090_2295">
                              <rect width="56" height="56" fill="white" transform="translate(0.5)"/>
                            </clipPath>
                          </defs>
                        </svg>
                    </span>
                    <span className='healthCare__serviceContentTitle inline-block my-5'>Check for Customer Support</span>
                    <span className='healthCare__servicePara inline-block text-center sm:text-left'>Look for responsive support via phone, email, or live chat to address payment processing issues promptly.</span>
                </div>

            </div>
        </motion.div> 

        <Nextstep />

        <Footer />

    </div>
  )
}

export default Healthcare