import React from 'react'
import './about.css'
import Navbar from '../nav/navbar'
import Nextstep from '../nextStep/nextstep'
import Footer from '../footer/footer'
import { Helmet } from "react-helmet"

const AboutUs = () => {
  return (
    <>

    <Helmet>
        <title>Payment Service Providers in India</title>
        <meta name='tag' content='Payment Service Provider'></meta>
        <meta name='description' content='Haoda Pay’s payment service providers deliver robust and secure solutions, ensuring fast, reliable, and hassle-free transactions for businesses across all industries.'/>
    </Helmet>

    <Navbar />

        <div className='haodapay__certificateBannerTop w-full'>
            <div className='aboutUs__bannerVideo w-full'>
                <video loop autoPlay muted playsinline className=' w-full h-full'>
                    <source src='\images\Web 800_1.mp4' type='video/mp4' />
                </video>
                <div className='aboutUs__bannerTitle'>
                    <span className='block text-center'>About Us</span>
                </div>
            </div>        
        </div>



       <div className='aboutus_body_background px-5 lg:px-0'>
            <div className='container mx-auto py-10 lg:py-20 px-5 lg:px-0'>
                 <div className='lg:flex justify-center padding_xAxis'>
                    <span className='block whatWeDo_page_heading text-center sm:text-left'>what we do</span>
                    <div className='whatWeDo_page_text xl:mr-20'>
                        <div className='text-center sm:text-left'>We at Haoda Pay are experts in providing payment and collecting services that support companies all over India. We guarantee that all of your payments, including incentive disbursements, vendor settlements, salary payouts, and daily pay cheques, are made on schedule each and every time. We streamline business financial management with automated solutions like EZ Collect for smooth collections and BBPS for recurring fees.</div>
                        <div className='py-5 text-center sm:text-left'>We provide quick, dependable solutions to maintain your financial operations stress-free, safe, and efficient, whether you're managing employee payments or subscription fees. Making payments is easy when you work with us.</div>
                    </div>
                 </div>
            </div>
       </div>

      
       <div className='container mx-auto px-5 lg:px-0'>
            <div className='lg:flex justify-center padding_xAxis'>
                <span className='block whatWeDo_page_heading text-center sm:text-left'>why We’re Here</span>
                <div className='whatWeDo_page_text xl:mr-20'>
                    <div className='text-center sm:text-left'>We started with a mission: to eliminate the pain points of payouts and collections for businesses. We saw companies struggling with delayed salaries, manual collections, and fragmented systems—so we built simple, automated solutions to solve these challenges.</div>
                    <div className='py-5 text-center sm:text-left'>Our goal? To make financial operations effortless. We believe that businesses, big or small, deserve fast, reliable, and flexible tools to manage their payouts and collections. Haoda Pay is here to empower businesses so they can focus on growth, not paperwork.</div>
                </div>
             </div>
       </div>

        <div className='container mx-auto px-5 lg:px-0 py-20'>
            <div className='lg:flex padding_xAxis'>
                 <span className='block whatWeDo_page_heading text-center sm:text-left'>Guiding values</span>
                 <div className='whatWeDo_page_text xl:mr-20'>
                    <div className='text-center sm:text-left'>At Haoda Pay, our values shape every decision we make and every service we deliver. They are the foundation of how we operate and the compass that guides us toward building lasting partnerships. Here’s what drives us:</div>
                 </div>
            </div>
        </div>



      <div className='aboutus_body_background'>
          <div className='container mx-auto py-10 lg:py-20 px-5'>  
                  <div className='grid sm:grid-cols-2 text-center sm:text-left lg:grid-cols-3 gap-8 md:gap-14 lg:gap-20 xl:gap-x-28 xl:gap-y-10 xl:px-16'>
                      <div>
                          <div className='guide__value__heading'>01</div>
                          <div className='guide__value__contentText font-semibold my-3'>Trust & Transparency</div>
                          <div className='guide__value__contentText font-normal'>We believe that trust is earned through honesty and transparency.
 We are committed to clear communication, fair practices, and building long-term relationships with our clients, partners, and employees.</div>
                      </div>

                      <div className=''>
                          <div className='guide__value__heading'>02</div>
                          <div className='guide__value__contentText font-semibold my-3'>Customer-Centric Approach</div>
                          <div className='guide__value__contentText font-normal'>Our customers are at the heart of everything we do. 
Every solution we design and every service we provide is geared towards solving real-world challenges and making financial operations simpler, faster, and more reliable for businesses.</div>
                      </div>

                      <div>
                          <div className='guide__value__heading'>03</div>
                          <div className='guide__value__contentText font-semibold my-3'>Innovation & Excellence</div>
                          <div className='guide__value__contentText font-normal'>We embrace change and strive to stay ahead of industry trends. Whether it's automating payouts or improving collections, 
                we believe in continuous innovation and setting high standards for ourselves to deliver the best for our clients.</div>
                      </div>

                      <div>
                          <div className='guide__value__heading'>04</div>
                          <div className='guide__value__contentText font-semibold my-3'>Collaboration & Teamwork</div>
                          <div className='guide__value__contentText font-normal'>At Haoda Pay, we believe great things happen when people work together. Our success comes from fostering a collaborative environment where diverse ideas thrive, and every voice matters.</div>
                      </div>

                      <div>
                          <div className='guide__value__heading'>05</div>
                          <div className='guide__value__contentText font-semibold my-3'>Integrity & Accountability</div>
                          <div className='guide__value__contentText font-normal'>We take responsibility for our actions and stay true to our promises. Integrity is at the core of our business—we believe in doing the right thing, even when no one is watching.</div>
                      </div>

                      <div>
                          <div className='guide__value__heading'>06</div>
                          <div className='guide__value__contentText font-semibold my-3'>Empowering Growth</div>
                          <div className='guide__value__contentText font-normal text-center sm:text-left'>We empower businesses by giving them the tools they need to succeed and scale effortlessly. We also focus on personal and professional growth within our team, encouraging learning, development, and leadership.</div>
                      </div>
                  </div>

              <div className='guide__value__contentText xl:px-16 xl:mr-20 mt-10 text-center sm:text-left'>Our values are more than just words—they are reflected in how we work, how we interact, and how we deliver value every day. At Haoda Pay, these principles guide us on our journey to build smarter financial solutions and empower businesses across India to grow with confidence.</div>
          </div>
      </div>

       
        <div className='ourTeam_background__img flex items-center py-20 md:py-32'>
           <div className='container mx-auto'>
               <div className='lg:flex lg:mx-0 xl:mx-16 px-5'>
                     <div className='outTeam__subHeading justify-center text-center sm:text-left'>Our Team</div>
                     <div className='outTeam__content__text mt-6 xl:mr-20 lg:mt-0'>
                       <div className='text-center sm:text-left'>We’re a group of visionary thinkers, industry experts, and tech enthusiasts who love solving real-world financial challenges. Our team brings together deep industry expertise with innovative thinking to create solutions that are fast, easy to use, and tailored for businesses across India.</div>
                       <div className='mt-4 text-center sm:text-left'>We believe in collaboration, constant learning, and staying customer-focused. Whether it’s building a better product or solving a client's issue, our team works together to deliver exceptional results. For us, your success is our biggest achievement.</div>
                     </div>
                </div>
            </div>
        </div>

        <Nextstep />

        <Footer />
    </>
  )
}

export default AboutUs