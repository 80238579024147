import React from 'react'
import './partnerPage.css'
import Marquee from "react-fast-marquee"
import Navbar from '../nav/navbar';
import Nextstep from '../nextStep/nextstep';
import Footer from '../footer/footer';
import { FaUser } from "react-icons/fa";
import { FaHeart } from "react-icons/fa6";
import { FaArrowRight } from "react-icons/fa";
import { easeInOut, motion } from 'framer-motion'
import { LuUser2 } from "react-icons/lu";


const PartnerPage = () => {
  return (
    <>

    <Navbar />

    <div className='partner__bannerBg haodapay__certificateBannerTop h-fit'>
             
              <div className='clubAssociation__BgVideo'>
                <video loop autoPlay muted playsinline className='w-full h-full'>
                  <source src='\images\Web 800_1.mp4' type='video/mp4' className='w-full h-full' />
                </video>
              </div>          

              <div className='clubAssociation__bannerContent container mx-auto 2xl:px-20 lg:flex justify-between items-center flex-wrap lg:flex-nowrap py-14 px-5 xl:px-10'>
                  <div className='lg:w-2/3 lg:pr-20 text-center lg:text-left'>
                      <div className='Partner_banner_heading Banner_head text-center lg:text-left'>Partner Payment Processing Solutions</div>
                      <div className='PartnerBanner_para_text banner-text my-8 pr-10'>Partner with Haoda pay to use advanced payment solutions in India and solve tough payment problems for your clients</div>
                      
                <a href='https://merchant-beta.mypayhaoda.com/auth/sign-in' target='_blank'><div className="sign__btn mt-5 grid place-content-center lg:block">
                <button className='signBanner__btn px-5 py-3 flex items-center gap-x-1'> Join as a partner
                    <span className='headerBanner__arrowNav grid place-content-center'>
                        <FaArrowRight className='headerBannerNav__arrow'/>                                   
                    </span>
                </button>
              </div>
              </a>

                  </div>
                  <div className='lg:w-1/2 flex justify-center lg:justify-end items-center mt-10 lg:mt-0 lg:pl-5 2xl:pl-0'>
                    <div className='clubAssociation__img'>
                        <img src='\images\Partner__bannerImg.jpg' alt='img' />
                    </div>
                  </div>
              </div>
        </div>

<div className='whyChoose__container'>
      <motion.div className='container mx-auto 2xl:px-20 py-10 md:py-20 px-5 md:px-0'
      initial={{ opacity: 0,
        y: "70px"
       }}
      whileInView={{ opacity: 1, y:"0px" }}
      transition={{ 
        duration: 1.2,
        ease: easeInOut,
        delay: 0.4 
       }}   
      viewport={{once:true}}
      >
            <span className='welCome__title block text-center'>Partner Benefits Overview:</span>
            <div className='flex justify-center flex-wrap xl:flex-nowrap gap-3 mt-10'>
                <div className='welcomeBox1 p-5'>
                    <div className='welcomeBox1__img'>
                        <img src='\images\partnerImg1.png' alt='img' />
                    </div>
                    <span className='block welcomeBoxTitle my-6 text-center md:text-left'>Revenue Opportunities:</span>
                    <span className='block welcomeBoxPara  text-center md:text-left'>Our flexible partnership models ensure that you receive consistent revenan estreams, providing financial stability and growth opportunities for your business</span>
                </div>

                <div className='welcomeBox2 p-5'>
                    <div className='welcomeBox1__img'>
                        <img src='\images\partnerImg2.png' alt='img' />
                    </div>
                    <span className='block welcomeBoxTitle my-6 text-center md:text-left'>Ease of Integration:</span>
                    <span className='block welcomeBoxPara text-center md:text-left'>Our team of integration specialists i dedicated to ensuring a smooth transition, allowing you t to focus on delivering value to your customers without hassle.</span>
                </div>

                <div className='welcomeBox3 p-5'>
                    <div className='welcomeBox1__img'>
                        <img src='\images\partnerImg3.png' alt='img' />
                    </div>
                    <span className='block welcomeBoxTitle my-6 text-center md:text-left'>Customised solutions:</span>
                    <span className='block welcomeBoxPara text-center md:text-left'>We offer personalised solutions tailored to your needs. Whether you want to expand products, increase revenue or Improve customer satisfaction, we work closely with you to achieve results</span>
                </div>

                <div className='welcomeBox4 p-5'>
                    <div className='welcomeBox1__img'>
                        <img src='\images\partnerImg4.png' alt='img' />
                    </div>
                    <span className='block welcomeBoxTitle my-6 text-center md:text-left'>Training and Support:</span>
                    <span className='block welcomeBoxPara text-center md:text-left'>Get round-the-clock support from the Haodapay team. From onboarding to dedicated implementation teams for your clients, we're here every step of the way to ensure swift deployment of payment solutions.</span>
                </div>
            </div>
        </motion.div>
    </div>

       <section className='whyChoose__containerLook py-10 md:py-20  container mx-auto'>
        <div className='Partner_body_heading flex text-center justify-center px-5'><div><span>The Impact of</span> <span className='Haoda_style pl-3'>Haoda</span><span className='Pay_style pr-3'>Pay</span><span>on Company Success</span></div></div>
        <motion.div className='container mx-auto flex justify-center'
              initial={{ opacity: 0,
                y: "70px"
               }}
              whileInView={{ opacity: 1, y:"0px" }}
              transition={{ 
                duration: 1.2,
                ease: easeInOut,
                delay: 0.4 
               }}   
              viewport={{once:true}}
        
        >
            
            <div className='comapny_width w-3/4 flex flex-wrap justify-center'>
                <div className='Company_success flex flex-col mx-5 lg:mx-2 my-3 justify-center px-3'>
                  <div className='flex justify-center'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56" fill="none">
                    <g clip-path="url(#clip0_3102_5614)">
                    <path d="M55.9901 18.666H0V20.9993C0 22.237 0.491665 23.424 1.36683 24.2992C2.242 25.1743 3.42899 25.666 4.66667 25.666H11.6667C12.9043 25.666 14.0913 25.1743 14.9665 24.2992C15.8417 23.424 16.3333 22.237 16.3333 20.9993C16.3333 22.237 16.825 23.424 17.7002 24.2992C18.5753 25.1743 19.7623 25.666 21 25.666H35C36.2377 25.666 37.4247 25.1743 38.2998 24.2992C39.175 23.424 39.6667 22.237 39.6667 20.9993C39.6667 22.237 40.1583 23.424 41.0335 24.2992C41.9087 25.1743 43.0957 25.666 44.3333 25.666H51.3333C52.571 25.666 53.758 25.1743 54.6332 24.2992C55.5083 23.424 56 22.237 56 20.9993V18.666H55.9901Z" fill="#F4B936"/>
                    <path d="M55.4263 16.3333L51.2433 1.69167C51.1038 1.20425 50.8095 0.775498 50.4047 0.470277C49.9999 0.165056 49.5067 -2.82715e-05 48.9998 3.63155e-09L38.3125 3.63155e-09L41.5792 16.3333H55.4263Z" fill="#392675"/>
                    <path d="M17.6881 3.63155e-09H7.0008C6.49384 -2.82715e-05 6.00066 0.165056 5.59587 0.470277C5.19108 0.775498 4.89672 1.20425 4.7573 1.69167L0.574219 16.3333H14.4214L17.6881 3.63155e-09Z" fill="#392675"/>
                    <path d="M33.5542 0H22.4464L19.1797 16.3333H36.8209L33.5542 0Z" fill="#392675"/>
                    <path d="M44.3359 27.9996C42.6131 28.0011 40.9507 27.3646 39.6693 26.2129C38.3879 27.3646 36.7255 28.0011 35.0026 27.9996H21.0026C19.2797 28.0011 17.6173 27.3646 16.3359 26.2129C15.0546 27.3646 13.3922 28.0011 11.6693 27.9996H4.66927C3.87387 27.998 3.08464 27.8599 2.33594 27.5913V53.6663C2.33594 54.2851 2.58177 54.8786 3.01935 55.3162C3.45694 55.7538 4.05043 55.9996 4.66927 55.9996H9.33594V34.9996C9.33594 34.3808 9.58177 33.7873 10.0194 33.3497C10.4569 32.9121 11.0504 32.6663 11.6693 32.6663H23.3359C23.9548 32.6663 24.5483 32.9121 24.9859 33.3497C25.4234 33.7873 25.6693 34.3808 25.6693 34.9996V55.9996H51.3359C51.9548 55.9996 52.5483 55.7538 52.9859 55.3162C53.4234 54.8786 53.6693 54.2851 53.6693 53.6663V27.5913C52.9206 27.8599 52.1313 27.998 51.3359 27.9996H44.3359ZM46.6693 41.9996C46.6693 42.6185 46.4234 43.212 45.9859 43.6496C45.5483 44.0871 44.9548 44.333 44.3359 44.333H32.6693C32.0504 44.333 31.4569 44.0871 31.0194 43.6496C30.5818 43.212 30.3359 42.6185 30.3359 41.9996V34.9996C30.3359 34.3808 30.5818 33.7873 31.0194 33.3497C31.4569 32.9121 32.0504 32.6663 32.6693 32.6663H44.3359C44.9548 32.6663 45.5483 32.9121 45.9859 33.3497C46.4234 33.7873 46.6693 34.3808 46.6693 34.9996V41.9996Z" fill="#392675"/>
                    </g>
                    <defs>
                      <clipPath id="clip0_3102_5614">
                          <rect width="56" height="56" fill="white"/>
                      </clipPath>
                    </defs>
                    </svg>
                   </div>
                   <div className='Partner_sub_heading1 flex justify-center text-center'>Incorporation Agents</div>
                </div>
                <div className='Company_success flex flex-col mx-5 lg:mx-2 my-3 justify-center px-3'>
                  <div className='flex justify-center'>
                     <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56" fill="none">
                        <g clip-path="url(#clip0_3102_5624)">
                        <path d="M33.6781 0C30.9218 0 29.1173 1.47656 28.0015 2.98582C26.8858 1.47656 25.0812 0 22.3251 0C18.2235 0 15.0078 3.41238 15.0078 7.80936C15.0078 12.4686 18.7814 15.6515 23.9986 20.0812C24.9173 20.8358 25.9017 21.689 26.9188 22.575C27.2471 22.8376 27.608 22.9687 28.0018 22.9687C28.3955 22.9687 28.7563 22.8376 29.0847 22.575C30.1019 21.6889 31.0862 20.8358 32.0049 20.0812C37.2218 15.6516 40.9954 12.4687 40.9954 7.80936C40.9954 3.41238 37.7796 0 33.6781 0Z" fill="#F4B936"/>
                        <path d="M23.8548 34.3764L16.1765 25.9438C16.1437 25.911 16.1108 25.8781 16.0782 25.8453C15.0148 24.7822 13.2642 24.4337 11.747 25.4843C10.2375 26.5673 9.90947 28.667 10.9922 30.1765C14.4971 35.0203 13.2624 33.3156 16.7672 38.1499C17.3251 38.8719 17.1609 39.9217 16.4062 40.4468C15.6224 40.9691 14.635 40.769 14.1093 40.0859L7.80936 31.3906V31.3578C7.02186 30.4717 6.56249 29.3234 6.56249 28.1093V10.0625C6.56249 8.19754 5.00915 6.78125 3.28124 6.78125C1.47656 6.78125 0 8.25781 0 10.0625V29.5859C0 31.0625 0.393749 32.5062 1.18125 33.7858L4.69218 39.6265C6.817 43.2143 9.84373 47.3247 9.84373 54.3593C9.84373 55.2781 10.5655 55.9999 11.4844 55.9999H26.25V40.6109C26.25 38.314 25.3968 36.0828 23.8548 34.3764Z" fill="#392675"/>
                        <path d="M52.7171 6.78125C50.9892 6.78125 49.4359 8.19754 49.4359 10.0625V28.1093C49.4359 29.3234 48.9765 30.4717 48.189 31.3578V31.3906L41.889 40.086C41.3634 40.7691 40.3758 40.9692 39.5922 40.4469C38.8376 39.9218 38.6733 38.872 39.2312 38.15C42.736 33.3157 41.5014 35.0203 45.0062 30.1766C46.0889 28.6671 45.7608 26.5674 44.2514 25.4844C42.7342 24.4338 40.9836 24.7822 39.9202 25.8454C39.8876 25.8782 39.8547 25.9111 39.8219 25.9439L32.1765 34.3439C30.6344 36.0171 29.7812 38.2487 29.7812 40.5454V55.9999H41.2806H44.514C45.4329 55.9999 46.1547 55.2781 46.1547 54.3593C46.1547 50.1397 47.4233 46.0163 49.3048 43.0061L54.8172 33.7858C55.6047 32.5062 55.9985 31.0625 55.9985 29.5859V10.0625C55.9984 8.25781 54.5218 6.78125 52.7171 6.78125Z" fill="#392675"/>
                        <path d="M13.125 13.3437C11.3203 13.3437 9.84375 14.8203 9.84375 16.625V22.8265C10.6641 22.2359 11.5501 21.8748 12.5016 21.678C13.8293 21.4888 15.0973 21.5662 16.4062 22.1703V16.625C16.4062 14.76 14.8529 13.3437 13.125 13.3437Z" fill="#392675"/>
                        <path d="M42.875 13.3437C41.0703 13.3437 39.5938 14.8203 39.5938 16.625V22.1703C40.9248 21.5559 42.0527 21.5228 43.3999 21.6454C44.4499 21.8748 45.3359 22.236 46.1234 22.7937C46.1234 22.7937 46.1562 22.7937 46.1562 22.8265V16.625C46.1562 14.76 44.6029 13.3437 42.875 13.3437Z" fill="#392675"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_3102_5624">
                        <rect width="56" height="56" fill="white"/>
                        </clipPath>
                        </defs>
                      </svg>
                   </div>
                   <div className='Partner_sub_heading1 flex justify-center text-center'>Accounting Services</div>
                </div>
                <div className='Company_success flex flex-col w-60 h-60 mx-5 lg:mx-2 my-3 justify-center px-3'>
                  <div className='flex justify-center'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56" fill="none">
                        <g clip-path="url(#clip0_3102_5624)">
                        <path d="M33.6781 0C30.9218 0 29.1173 1.47656 28.0015 2.98582C26.8858 1.47656 25.0812 0 22.3251 0C18.2235 0 15.0078 3.41238 15.0078 7.80936C15.0078 12.4686 18.7814 15.6515 23.9986 20.0812C24.9173 20.8358 25.9017 21.689 26.9188 22.575C27.2471 22.8376 27.608 22.9687 28.0018 22.9687C28.3955 22.9687 28.7563 22.8376 29.0847 22.575C30.1019 21.6889 31.0862 20.8358 32.0049 20.0812C37.2218 15.6516 40.9954 12.4687 40.9954 7.80936C40.9954 3.41238 37.7796 0 33.6781 0Z" fill="#F4B936"/>
                        <path d="M23.8548 34.3764L16.1765 25.9438C16.1437 25.911 16.1108 25.8781 16.0782 25.8453C15.0148 24.7822 13.2642 24.4337 11.747 25.4843C10.2375 26.5673 9.90947 28.667 10.9922 30.1765C14.4971 35.0203 13.2624 33.3156 16.7672 38.1499C17.3251 38.8719 17.1609 39.9217 16.4062 40.4468C15.6224 40.9691 14.635 40.769 14.1093 40.0859L7.80936 31.3906V31.3578C7.02186 30.4717 6.56249 29.3234 6.56249 28.1093V10.0625C6.56249 8.19754 5.00915 6.78125 3.28124 6.78125C1.47656 6.78125 0 8.25781 0 10.0625V29.5859C0 31.0625 0.393749 32.5062 1.18125 33.7858L4.69218 39.6265C6.817 43.2143 9.84373 47.3247 9.84373 54.3593C9.84373 55.2781 10.5655 55.9999 11.4844 55.9999H26.25V40.6109C26.25 38.314 25.3968 36.0828 23.8548 34.3764Z" fill="#392675"/>
                        <path d="M52.7171 6.78125C50.9892 6.78125 49.4359 8.19754 49.4359 10.0625V28.1093C49.4359 29.3234 48.9765 30.4717 48.189 31.3578V31.3906L41.889 40.086C41.3634 40.7691 40.3758 40.9692 39.5922 40.4469C38.8376 39.9218 38.6733 38.872 39.2312 38.15C42.736 33.3157 41.5014 35.0203 45.0062 30.1766C46.0889 28.6671 45.7608 26.5674 44.2514 25.4844C42.7342 24.4338 40.9836 24.7822 39.9202 25.8454C39.8876 25.8782 39.8547 25.9111 39.8219 25.9439L32.1765 34.3439C30.6344 36.0171 29.7812 38.2487 29.7812 40.5454V55.9999H41.2806H44.514C45.4329 55.9999 46.1547 55.2781 46.1547 54.3593C46.1547 50.1397 47.4233 46.0163 49.3048 43.0061L54.8172 33.7858C55.6047 32.5062 55.9985 31.0625 55.9985 29.5859V10.0625C55.9984 8.25781 54.5218 6.78125 52.7171 6.78125Z" fill="#392675"/>
                        <path d="M13.125 13.3437C11.3203 13.3437 9.84375 14.8203 9.84375 16.625V22.8265C10.6641 22.2359 11.5501 21.8748 12.5016 21.678C13.8293 21.4888 15.0973 21.5662 16.4062 22.1703V16.625C16.4062 14.76 14.8529 13.3437 13.125 13.3437Z" fill="#392675"/>
                        <path d="M42.875 13.3437C41.0703 13.3437 39.5938 14.8203 39.5938 16.625V22.1703C40.9248 21.5559 42.0527 21.5228 43.3999 21.6454C44.4499 21.8748 45.3359 22.236 46.1234 22.7937C46.1234 22.7937 46.1562 22.7937 46.1562 22.8265V16.625C46.1562 14.76 44.6029 13.3437 42.875 13.3437Z" fill="#392675"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_3102_5624">
                        <rect width="56" height="56" fill="white"/>
                        </clipPath>
                        </defs>
                      </svg>
                   </div>
                   <div className='Partner_sub_heading1 flex justify-center text-center'>Law <br />Firms</div>
                </div>
                <div className='Company_success flex flex-col w-60 h-60 mx-5 lg:mx-2 my-3 justify-center px-3'>
                  <div className='flex justify-center'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56" fill="none">
                       <g clip-path="url(#clip0_3102_5642)">
                       <path d="M17.5722 8.66983C17.5722 7.76377 18.3067 7.0292 19.2128 7.0292H36.7858C37.6918 7.0292 38.4264 7.76377 38.4264 8.66983V10.7772H45.4555V8.66983C45.4555 3.88237 41.5821 0 36.7856 0H19.2128C14.4246 0 10.543 3.87352 10.543 8.66983V10.7772H17.5722V8.66983Z" fill="#392675"/>
                       <path d="M29.8747 31.5136V26.125H26.1267V31.5136C26.1267 32.4196 25.3922 33.1542 24.4861 33.1542H19.0977V36.9022H24.4862C25.3923 36.9022 26.1269 37.6367 26.1269 38.5428V43.9314H29.8748V38.5428C29.8748 37.6367 30.6094 36.9022 31.5154 36.9022H36.9039V33.1542H31.5153C30.6093 33.1542 29.8747 32.4196 29.8747 31.5136Z" fill="#F4B936"/>
                       <path d="M49.0875 14.0586H6.9125C3.08908 14.0586 0 17.1528 0 20.9711V49.0878C0 52.9112 3.09411 56.0003 6.9125 56.0003H49.0875C52.911 56.0003 56 52.9062 56 49.0878V20.9711C56 17.1481 52.9063 14.0586 49.0875 14.0586ZM40.1844 38.544C40.1844 39.4501 39.4498 40.1847 38.5438 40.1847H33.1552V45.5732C33.1552 46.4793 32.4206 47.2139 31.5145 47.2139H24.4853C23.5793 47.2139 22.8447 46.4793 22.8447 45.5732V40.1847H17.4563C16.5502 40.1847 15.8156 39.4501 15.8156 38.544V31.5148C15.8156 30.6088 16.5502 29.8742 17.4563 29.8742H22.8448V24.4856C22.8448 23.5796 23.5794 22.845 24.4855 22.845H31.5147C32.4207 22.845 33.1553 23.5796 33.1553 24.4856V29.8742H38.5438C39.4498 29.8742 40.1844 30.6088 40.1844 31.5148V38.544Z" fill="#392675"/>
                       </g>
                       <defs>
                       <clipPath id="clip0_3102_5642">
                       <rect width="56" height="56" fill="white"/>
                       </clipPath>
                       </defs>
                     </svg>
                   </div>
                   <div className='Partner_sub_heading1 flex justify-center text-center'>Software Companies</div>
                </div>
                <div className='Company_success flex flex-col w-60 h-60 mx-5 lg:mx-2 my-3 justify-center px-3'>
                  <div className='flex justify-center'>
                     <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56" fill="none">
                         <path d="M3.39062 13.2344H8.3125C9.21769 13.2344 9.95312 13.9698 9.95312 14.875V41.2344C9.95312 45.3129 12.9542 48.6789 16.8587 49.3064C16.6444 49.8578 16.5156 50.4518 16.5156 51.0781C16.5156 53.7922 18.7235 56 21.4375 56C24.1515 56 26.3594 53.7922 26.3594 51.0781C26.3594 50.5002 26.241 49.9532 26.0572 49.4364H39.7866C39.6027 49.9532 39.4844 50.5002 39.4844 51.0781C39.4844 53.7922 41.6922 56 44.4062 56C47.1203 56 49.3281 53.7922 49.3281 51.0781C49.3281 48.3641 47.1203 46.1562 44.4062 46.1562H18.1562C15.915 46.1562 14.0402 44.6416 13.4477 42.5888L52.6094 36.0609V19.7969H50.2692C48.1195 27.3602 41.165 32.9219 32.9219 32.9219C24.6787 32.9219 17.7242 27.3602 15.5746 19.7969H13.2344V14.875C13.2344 12.161 11.0265 9.95312 8.3125 9.95312H3.39062V13.2344Z" fill="#392675"/>
                         <path d="M32.9225 0C31.0428 0 28.3992 5.19225 28.0664 13.2344H37.7788C37.4459 5.19225 34.8023 0 32.9225 0Z" fill="#F4B936"/>
                         <path d="M38.3828 1.07617C39.9849 4.21239 40.9081 8.76622 41.0896 13.2353H47.52C46.9029 7.75002 43.3307 3.05783 38.3828 1.07617Z" fill="#F4B936"/>
                         <path d="M32.9227 29.6406C34.8024 29.6406 37.446 24.5577 37.7788 16.5156H28.0664C28.3993 24.5577 31.0429 29.6406 32.9227 29.6406Z" fill="#F4B936"/>
                         <path d="M38.3828 28.5654C43.3307 26.5837 46.903 22.0009 47.52 16.5156H41.0896C40.9081 20.9847 39.9849 25.4291 38.3828 28.5654Z" fill="#F4B936"/>
                         <path d="M18.3203 16.5156C18.9373 22.0009 22.5096 26.5837 27.4575 28.5654C25.8554 25.4291 24.9322 20.9847 24.7507 16.5156H18.3203Z" fill="#F4B936"/>
                         <path d="M18.3203 13.1591H24.7507C24.9324 8.69005 25.8554 4.13622 27.4576 1C22.5096 2.98166 18.9374 7.67384 18.3203 13.1591Z" fill="#F4B936"/>
                     </svg>
                   </div>
                   <div className='Partner_sub_heading1 flex justify-center text-center'>E-Commerce Platforms</div>
                </div>
                <div className='Company_success flex flex-col w-60 h-60 mx-5 lg:mx-2 my-3 justify-center px-3'>
                  <div className='flex justify-center'>
                     <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56" fill="none">
                         <path d="M3.39062 13.2344H8.3125C9.21769 13.2344 9.95312 13.9698 9.95312 14.875V41.2344C9.95312 45.3129 12.9542 48.6789 16.8587 49.3064C16.6444 49.8578 16.5156 50.4518 16.5156 51.0781C16.5156 53.7922 18.7235 56 21.4375 56C24.1515 56 26.3594 53.7922 26.3594 51.0781C26.3594 50.5002 26.241 49.9532 26.0572 49.4364H39.7866C39.6027 49.9532 39.4844 50.5002 39.4844 51.0781C39.4844 53.7922 41.6922 56 44.4062 56C47.1203 56 49.3281 53.7922 49.3281 51.0781C49.3281 48.3641 47.1203 46.1562 44.4062 46.1562H18.1562C15.915 46.1562 14.0402 44.6416 13.4477 42.5888L52.6094 36.0609V19.7969H50.2692C48.1195 27.3602 41.165 32.9219 32.9219 32.9219C24.6787 32.9219 17.7242 27.3602 15.5746 19.7969H13.2344V14.875C13.2344 12.161 11.0265 9.95312 8.3125 9.95312H3.39062V13.2344Z" fill="#392675"/>
                         <path d="M32.9225 0C31.0428 0 28.3992 5.19225 28.0664 13.2344H37.7788C37.4459 5.19225 34.8023 0 32.9225 0Z" fill="#F4B936"/>
                         <path d="M38.3828 1.07617C39.9849 4.21239 40.9081 8.76622 41.0896 13.2353H47.52C46.9029 7.75002 43.3307 3.05783 38.3828 1.07617Z" fill="#F4B936"/>
                         <path d="M32.9227 29.6406C34.8024 29.6406 37.446 24.5577 37.7788 16.5156H28.0664C28.3993 24.5577 31.0429 29.6406 32.9227 29.6406Z" fill="#F4B936"/>
                         <path d="M38.3828 28.5654C43.3307 26.5837 46.903 22.0009 47.52 16.5156H41.0896C40.9081 20.9847 39.9849 25.4291 38.3828 28.5654Z" fill="#F4B936"/>
                         <path d="M18.3203 16.5156C18.9373 22.0009 22.5096 26.5837 27.4575 28.5654C25.8554 25.4291 24.9322 20.9847 24.7507 16.5156H18.3203Z" fill="#F4B936"/>
                         <path d="M18.3203 13.1591H24.7507C24.9324 8.69005 25.8554 4.13622 27.4576 1C22.5096 2.98166 18.9374 7.67384 18.3203 13.1591Z" fill="#F4B936"/>
                     </svg>
                   </div>
                   <div className='Partner_sub_heading1 flex justify-center text-center'>Auditing Services</div>
                </div>
                <div className='Company_success flex flex-col w-60 h-60 mx-5 lg:mx-2 my-3 justify-center px-3'>
                  <div className='flex justify-center'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56" fill="none">
                        <g clip-path="url(#clip0_3102_5661)">
                           <path d="M54.3594 9.84375H52.7188V44.5156C52.7188 47.2295 50.5108 49.4375 47.7969 49.4375H34.1311C33.3111 49.4375 32.5501 49.8448 32.0952 50.5271C31.1801 51.8995 29.6493 52.7188 28 52.7188C26.3507 52.7188 24.8199 51.8995 23.9049 50.5272C23.45 49.8448 22.689 49.4375 21.869 49.4375H8.20312C5.4892 49.4375 3.28125 47.2295 3.28125 44.5156V9.84375H1.64062C0.734562 9.84375 0 10.5783 0 11.4844V51.0781C0 51.9842 0.734562 52.7188 1.64062 52.7188H20.1662C21.5277 52.7188 22.7318 53.5866 23.1623 54.8781C23.3857 55.5481 24.0126 56 24.7188 56H31.2812C31.9874 56 32.6143 55.5481 32.8377 54.8781C33.2682 53.5866 34.4723 52.7188 35.8338 52.7188H54.3594C55.2654 52.7188 56 51.9842 56 51.0781V11.4844C56 10.5783 55.2654 9.84375 54.3594 9.84375Z" fill="#F4B936"/>
                           <path d="M18.1562 42.875H14.7656C12.0517 42.875 9.84375 40.667 9.84375 37.9531V4.375H8.20312C7.29706 4.375 6.5625 5.10956 6.5625 6.01562V44.5156C6.5625 45.4217 7.29706 46.1562 8.20312 46.1562H21.8689C22.1922 46.1562 22.5109 46.1856 22.8236 46.2382C22.1701 44.2862 20.3255 42.875 18.1562 42.875Z" fill="#392675"/>
                           <path d="M47.7964 4.375H46.1558V37.9531C46.1558 40.667 43.9478 42.875 41.2339 42.875H37.8433C35.674 42.875 33.8294 44.2862 33.1758 46.2382C33.4885 46.1856 33.8073 46.1562 34.1305 46.1562H47.7964C48.7024 46.1562 49.437 45.4217 49.437 44.5156V6.01562C49.437 5.10956 48.7024 4.375 47.7964 4.375Z" fill="#392675"/>
                           <path d="M21.4375 0H14.7656C13.8596 0 13.125 0.734562 13.125 1.64062V37.9531C13.125 38.8592 13.8596 39.5938 14.7656 39.5938H18.1562C22.6795 39.5938 26.3594 43.2737 26.3594 47.7969V4.93511C26.3594 2.23628 24.1917 0.0180469 21.4928 0.000109375C21.4744 0.000109375 21.456 0 21.4375 0Z" fill="#392675"/>
                           <path d="M41.2344 0H34.5625C34.544 0 34.5256 0.000109375 34.5072 0.00021875C31.8083 0.0180469 29.6406 2.23628 29.6406 4.93511V47.7969C29.6406 43.2737 33.3205 39.5938 37.8438 39.5938H41.2344C42.1404 39.5938 42.875 38.8592 42.875 37.9531V1.64062C42.875 0.734562 42.1404 0 41.2344 0Z" fill="#392675"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_3102_5661">
                        <rect width="56" height="56" fill="white"/>
                        </clipPath>
                        </defs>
                     </svg>
                   </div>
                   <div className='Partner_sub_heading1 flex justify-center text-center'>Incorporation Agents</div>
                </div>
            </div>
        </motion.div>
      </section>

      <section className='whyChoose__container md:py-20 py-10 container mx-auto'>
        <motion.div
                      initial={{ opacity: 0,
                        y: "70px"
                       }}
                      whileInView={{ opacity: 1, y:"0px" }}
                      transition={{ 
                        duration: 1.2,
                        ease: easeInOut,
                        delay: 0.4 
                       }}   
                      viewport={{once:true}}
        
        >
         <div className='Partner_body_heading text-center'>Discover Your Ideal Partnership at<span className='px-3'><span className='Haoda_style'>Haoda</span><span className='Pay_style'>Pay</span></span></div>
         <div className='flex container mx-auto justify-center py-5 md:py-10'>
            <div className='Partnership_content px-10 flex justify-center flex-wrap-reverse lg:flex-nowrap'>
               <div className='partmership_image lg:w-1/4 flex justify-start'>
                   <div className='flex'><img className='Payment_method_img' src='\images\partnerImg5.png' alt='iamge' /></div>
               </div>
               <div className='partmership_text pl-20 lg:w-2/5 flex justify-start items-center'>
                   <div className=''>
                       <div className='Partner_haodapay Partner_sub_heading'>Referral Partners:</div>
                       <div className='Partner_para_text mt-6'>Our referral program serves corporate service providers like company agents, tax advisors, accountants, lawyers, and payment providers. Offer your clients easy access to our multi-currency accounts to join a new entrepreneur network and receive dedicated support.</div>
                   </div>   
               </div>
            </div>
          </div>

          <div className='flex container mx-auto justify-center'>
            <div className='Partnership_content1 px-5 flex justify-center py-5 md:py-10'>
              <div className='partmership_text1 w-2/5 flex justify-start items-center'>
                   <div className=''>
                       <div className='Partner_haodapay Partner_sub_heading'>Promo Partners: Success Through Collaboration</div>
                       <div className='Partner_para_text mt-6'>We're teaming up with agencies, SaaS platforms, and big corporations to bring our clients exclusive offers and bonusex, fostering mutual business growth and socce</div>
                   </div>   
               </div>
               <div className='partmership_image1 w-1/4 flex justify-end'>
                   <div className='flex'><img className='Payment_method_img' src='\images\partnerImg6.png' alt='iamge' /></div>
               </div>
            </div>
          </div>
        </motion.div>
      </section>

      {/* Testimonial section */}

      <section className='whyChoose__containerLook py-10 sm:py-20 px-5 md:px-0'>
            <div className='grid place-content-center'>
                <div className='testimonial__loveContainer flex justify-center items-center gap-1'>
                    <FaHeart className='testimonial__loveHeart'/>
                    <span className='testimonial__loveTitle'>Loved by experts</span>
                </div>
            </div>            
            <span className='testimonial__title block text-center my-3 md:my-5'>A community of product thinkers</span>
            <span className='testimonial__subtitle block text-center'>Join our Slack community <span className='testimonial__subtitlePrime'>and see what the hype is all about.</span> </span>

            <Marquee className='pt-14 pb-5' pauseOnHover>
                <div className='testimonial__box p-6 mr-6'>
                    <span className='testimonial__boxContent inline-block'>Haoda Pay has transformed our payment processes. The seamless integration and reliable service allow us to focus on our core business without worrying about financial transactions.</span>
                    <div className='mt-5 lg:mt-16 flex justify-between items-center'>
                        <div>
                            <span className='block testimonial__contentName'>Jeetu</span>
                            <span className='block testimonial__contentCompany'>Jai Bhole</span>
                            {/* <span className='block testimonial__contentCompany'>Puls</span> */}
                        </div>
                        <div className='testimonial__Img'>
                        <LuUser2 className='testimonial__FaUser' />
                        </div>
                    </div>
                </div>
                <div className='testimonial__box p-6 mr-6'>
                    <span className='testimonial__boxContent inline-block'>With Haoda Pay, our salary payouts are now timely and efficient. The user-friendly interface makes it easy for our team to manage payments and track expenses.</span>
                    <div className='mt-5 lg:mt-16 flex justify-between items-center'>
                        <div>
                            <span className='block testimonial__contentName'>Shubham Kumar</span>
                            <span className='block testimonial__contentCompany'>Cashless Master</span>
                            {/* <span className='block testimonial__contentCompany'>Puls</span> */}
                        </div>
                        <div className='testimonial__Img'>
                        <LuUser2 className='testimonial__FaUser' />
                        </div>
                    </div>
                </div>
                <div className='testimonial__box p-6 mr-6'>
                    <span className='testimonial__boxContent inline-block'>Switching to Haoda Pay was one of the best decisions we made for our business. The level of efficiency and transparency in our transactions is unparalleled.</span>
                    <div className='mt-5 lg:mt-16 flex justify-between items-center'>
                        <div>
                            <span className='block testimonial__contentName'>Gaurav Kumar</span>
                            <span className='block testimonial__contentCompany'>Aryan Concrete</span>
                            {/* <span className='block testimonial__contentCompany'>Puls</span> */}
                        </div>
                        <div className='testimonial__Img'>
                        <LuUser2 className='testimonial__FaUser' />
                        </div>
                    </div>
                </div>
                <div className='testimonial__box p-6 mr-6'>
                    <span className='testimonial__boxContent inline-block'>The support from the Haoda Pay team is exceptional. Whenever we have questions or need assistance, they’re always there to help us promptly. Truly a valuable partner.</span>
                    <div className='mt-5 lg:mt-16 flex justify-between items-center'>
                        <div>
                            <span className='block testimonial__contentName'>Komik Kalal</span>
                            <span className='block testimonial__contentCompany'>Hr International</span>
                            {/* <span className='block testimonial__contentCompany'>Puls</span> */}
                        </div>
                        <div className='testimonial__Img'>
                        <LuUser2 className='testimonial__FaUser' />
                        </div>
                    </div>
                </div>

                <div className='testimonial__box p-6 mr-6'>
                    <span className='testimonial__boxContent inline-block'>Thanks to Haoda Pay, our online payment processing is now secure and efficient. Customer feedback has been overwhelmingly positive!
                    </span>
                    <div className='mt-5 lg:mt-16 flex justify-between items-center'>
                        <div>
                            <span className='block testimonial__contentName'>Sai Doss</span>
                            <span className='block testimonial__contentCompany'>Finxee Solutions</span>
                            {/* <span className='block testimonial__contentCompany'>Puls</span> */}
                        </div>
                        <div className='testimonial__Img'>
                        <LuUser2 className='testimonial__FaUser' />
                        </div>
                    </div>
                </div>

                <div className='testimonial__box p-6 mr-6'>
                    <span className='testimonial__boxContent inline-block'>I highly recommend Haoda Pay for any business looking to simplify their payment processes. Their commitment to service excellence is evident in everything they do.
                    </span>
                    <div className='mt-5 lg:mt-16 flex justify-between items-center'>
                        <div>
                            <span className='block testimonial__contentName'>Hajaalavudeen Fathimabegum</span>
                            <span className='block testimonial__contentCompany'>Mariyan civil constructions</span>
                            {/* <span className='block testimonial__contentCompany'>Puls</span> */}
                        </div>
                        <div className='testimonial__Img'>
                        <LuUser2 className='testimonial__FaUser' />
                        </div>
                    </div>
                </div>
                <div className='testimonial__box p-6 mr-6'>
                    <span className='testimonial__boxContent inline-block'>The level of service we receive from Haoda Pay is top-notch. They genuinely care about our success and are always available to assist."

                    </span>
                    <div className='mt-5 lg:mt-16 flex justify-between items-center'>
                        <div>
                            <span className='block testimonial__contentName'>Nirbhay Kumar</span>
                            <span className='block testimonial__contentCompany'>Planet Technology
                            </span>
                            {/* <span className='block testimonial__contentCompany'>Puls</span> */}
                        </div>
                        <div className='testimonial__Img'>
                        <LuUser2 className='testimonial__FaUser' />
                        </div>
                    </div>
                </div>
                <div className='testimonial__box p-6 mr-6'>
                    <span className='testimonial__boxContent inline-block'>THaoda Pay has helped us reduce transaction costs significantly. Their competitive pricing and efficiency make them an invaluable partner.

                    </span>
                    <div className='mt-5 lg:mt-16 flex justify-between items-center'>
                        <div>
                            <span className='block testimonial__contentName'>Dhinakar Kumar</span>
                            <span className='block testimonial__contentCompany'>SK Associates 
                            </span>
                            {/* <span className='block testimonial__contentCompany'>Puls</span> */}
                        </div>
                        <div className='testimonial__Img'>
                        <LuUser2 className='testimonial__FaUser' />
                        </div>
                    </div>
                </div>
            </Marquee>

            <Marquee className='pt-6 pb-5' direction='right' pauseOnHover>
                <div className='testimonial__box p-6 mr-6'>
                    <span className='testimonial__boxContent inline-block'> As a small business, we struggled with payment processing until we found Haoda Pay. Their solutions are affordable and tailored to our needs, making financial management a breeze</span>
                    <div className='mt-5 lg:mt-16 flex justify-between items-center'>
                        <div>
                            <span className='block testimonial__contentName'></span>
                            <span className='block testimonial__contentCompany'>SKVB International Pvt Ltd</span>
                            {/* <span className='block testimonial__contentCompany'>Puls</span> */}
                        </div>
                        <div className='testimonial__Img'>
                        <LuUser2 className='testimonial__FaUser' />
                        </div>
                    </div>
                </div>
                <div className='testimonial__box p-6 mr-6'>
                    <span className='testimonial__boxContent inline-block'> "The detailed reporting features of Haoda Pay help us keep track of our finances effortlessly. I love how organized and clear everything is!"</span>
                    <div className='mt-5 lg:mt-16 flex justify-between items-center'>
                        <div>
                            <span className='block testimonial__contentName'>Ashish Thakur</span>
                            <span className='block testimonial__contentCompany'>Global Handicraft
                            </span>
                            {/* <span className='block testimonial__contentCompany'>Puls</span> */}
                        </div>
                        <div className='testimonial__Img'>
                        <LuUser2 className='testimonial__FaUser' />
                        </div>
                    </div>
                </div>
                <div className='testimonial__box p-6 mr-6'>
                    <span className='testimonial__boxContent inline-block'>Our membership collection process has improved significantly since we started using Haoda Pay. It's user-friendly, and our members appreciate the convenience!</span>
                    <div className='mt-5 lg:mt-16 flex justify-between items-center'>
                        <div>
                            <span className='block testimonial__contentName'>Abhisheik</span>
                            <span className='block testimonial__contentCompany'>Girija Milk </span>
                            {/* <span className='block testimonial__contentCompany'>Puls</span> */}
                        </div>
                        <div className='testimonial__Img'>
                        <LuUser2 className='testimonial__FaUser' />
                        </div>
                    </div>
                </div>
                <div className='testimonial__box p-6 mr-6'>
                    <span className='testimonial__boxContent inline-block'>Our membership collection process has improved significantly since we started using Haoda Pay. It's user-friendly, and our members appreciate the convenience!.</span>
                    <div className='mt-5 lg:mt-16 flex justify-between items-center'>
                        <div>
                            <span className='block testimonial__contentName'>Hardikkumar Dalsukhbhai</span>
                            <span className='block testimonial__contentCompany'>Hari Krishna Crafts</span> 
                            {/* <span className='block testimonial__contentCompany'>Puls</span> */}
                        </div>
                        <div className='testimonial__Img'>
                            <LuUser2 className='testimonial__FaUser' />
                        </div>
                    </div>
                </div>

                <div className='testimonial__box p-6 mr-6'>
                    <span className='testimonial__boxContent inline-block'>The integration process was smooth and hassle-free. Haoda Pay worked closely with us to ensure everything was set up correctly.</span>
                    <div className='mt-5 lg:mt-16 flex justify-between items-center'>
                        <div>
                            <span className='block testimonial__contentName'>Bishamber Singh</span>
                            <span className='block testimonial__contentCompany'>Prince trading </span> 
                            {/* <span className='block testimonial__contentCompany'>Puls</span> */}
                        </div>
                        <div className='testimonial__Img'>
                            <LuUser2 className='testimonial__FaUser' />
                        </div>
                    </div>
                </div>

                <div className='testimonial__box p-6 mr-6'>
                    <span className='testimonial__boxContent inline-block'>The customer feedback on our new payment options has been fantastic. Haoda Pay has made it easy for our clients to pay, increasing our sales.</span>
                    <div className='mt-5 lg:mt-16 flex justify-between items-center'>
                        <div>
                            <span className='block testimonial__contentName'>Rakeshwar Sharma</span>
                            <span className='block testimonial__contentCompany'>At Moon Ventures </span> 
                            {/* <span className='block testimonial__contentCompany'>Puls</span> */}
                        </div>
                        <div className='testimonial__Img'>
                            <LuUser2 className='testimonial__FaUser' />
                        </div>
                    </div>
                </div>

                <div className='testimonial__box p-6 mr-6'>
                    <span className='testimonial__boxContent block '>Haoda Pay’s dashboard provides us with real-time insights, allowing us to monitor our financial health at a glance.</span>
                    <div className='mt-5 lg:mt-16 flex justify-between items-center'>
                        <div>
                            <span className='block testimonial__contentName'>Mathu suthan</span>
                            <span className='block testimonial__contentCompany'>Trauslink</span> 
                            {/* <span className='block testimonial__contentCompany'>Puls</span> */}
                        </div>
                        <div className='testimonial__Img'>
                            <LuUser2 className='testimonial__FaUser' />
                        </div>
                    </div>
                </div>
            </Marquee>

        </section>

      <Nextstep />

      <Footer />

    </>
  )
}

export default PartnerPage